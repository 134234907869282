import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Box, Typography } from "@material-ui/core";
import HowItWorksHeader from "./HowItWorksHeader";
import HowItWorksItem from "./HowItWorksItem";
import { makeStyles } from "@material-ui/core/styles";
import "./HowItWorksMenu.css";
import PrimaryLink from "../../buttons/PrimaryLink";
import PrimaryButton from "../../buttons/PrimaryButton";
import useNav from "../../../hooks/useNav";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";

const usesStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "block",
      marginBottom: "30px"
    }
  },
  text: {
    fontSize: "24px",
    lineHeight: "31px",
    color: "#2D2D2D",
    paddingBottom: "12px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 12
    }
  },
  subText: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#2D2D2D",
    paddingBottom: "12px"
  },
  textBold: {
    fontWeight: "bold"
  },
  addToWishlistButtonStyle: {
    width: "230px",
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    color: "#E92983 !important",
    background: "#ffffff !important",
    border: "#f0017c 1px solid !important",
    marginBottom: "60px",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0
    }
  }
}));

// List of items
const list = [
  { name: "gucci", title: "Gucci", src: "/assets/gucci.jpeg", toUrl: "/store/store/2879204/Gucci" },
  {
    name: "heels",
    title: "Heels & Sandals",
    src: "/assets/heels.jpeg",
    toUrl: "/store/store/1748846/Heels%20&%20Sandals"
  },
  {
    name: "dolce",
    title: "Dolce & Gabbana",
    src: "/assets/dolce.jpeg",
    toUrl: "/store/store/2878907/Dolce%20&%20Gabbana"
  },
  { name: "diamonds", title: "Diamonds", src: "/assets/diamonds.jpeg", toUrl: "/store/store/2878909/Diamonds" },
  { name: "fendi", title: "Fendi", src: "/assets/fendi.jpeg", toUrl: "/store/store/2878909/Fendi" },
  { name: "earrings", title: "Earrings", src: "/assets/earrings.jpeg", toUrl: "/store/store/114672/Earrings" },
  { name: "givenchy", title: "Givenchy", src: "/assets/givenchy.jpeg", toUrl: "/store/store/2878912/Givenchy" },
  { name: "necklaces", title: "Nicklaces", src: "/assets/necklaces.jpeg", toUrl: "/store/store/252022/Necklaces" },
  {
    name: "alexander",
    title: "Alexander McQueen",
    src: "/assets/alexander.jpeg",
    toUrl: "/store/store/2878899/Alexander%20McQueen"
  },
  { name: "boots", title: "Boots", src: "/assets/boots.jpeg", toUrl: "/store/store/1748849/Boots" },
  {
    name: "salvatore",
    title: "Salvatore Ferragamo",
    src: "/assets/salvatore.jpeg",
    toUrl: "/store/store/2878914/Salvatore%20Ferragamo"
  },
  { name: "and_more", title: "And More!" }
];

// One item component selected prop will be passed
interface MenuItemProps {
  title: string;
  src: string;
  toUrl: string;
  selected: string;
}
const MenuItem = ({ title, src, toUrl, selected }: MenuItemProps) => {
  const content = (
    <div>
      <img src={src} alt={title} />
      <div className={`menu-item ${selected ? "active" : ""}`}>
        <Typography className={usesStyles().root}>{title}</Typography>
      </div>
    </div>
  );

  return src ? (
    <PrimaryLink text={content} href={toUrl} underline="none" />
  ) : (
    <Typography className={usesStyles().root}>{title}</Typography>
  );
};

// All items component
export const Menu = (list, selected) =>
  list.map((el) => {
    const { name, title, src, toUrl } = el;

    return <MenuItem key={name} title={title} src={src} toUrl={toUrl} selected={selected} />;
  });

const selected = "gucci";

const HowItWorks2: React.FC = () => {
  const { isDesktop } = useMediaBreakpoints();
  const classes = usesStyles();
  const menuItems = Menu(list, selected);
  const { goTo } = useNav();

  const [selectedItem, setSelectedItem] = useState(selected);

  const onSelect = (key) => {
    setSelectedItem(key);
  };

  const howItWorksStep1Text = (
    <div>
      <Typography className={classes.text}>1. Create a Wishlist</Typography>
      <Typography className={classes.subText}>
        Window shopping has never been this fun! Just browse through our online mall and choose from a huge selection of
        fabulous gifts. Once you find your must-haves, just click the heart icon to add all your faves to your wishlist.
        Now your fans will know exactly what makes you tick!
      </Typography>
      <Typography className={classes.subText}>
        And if you’re all about unboxing surprises, you’ll love to share your unique Gift.me link. With your vanity URL,
        it’s exciting to skip the list and let your followers do the shopping for you!
      </Typography>
    </div>
  );

  const howItWorksStep2Text = (
    <div>
      <Typography className={classes.text}>2. Spread The Word</Typography>
      <Typography className={classes.subText}>
        Once you’ve created your wishlist, it’s time to share it with the world. It’s a great way to connect with your
        fans and let them get to know you a little better. Friends and family also love our personal gifting solution,
        because they know these are the gifts you’ll never exchange.
      </Typography>
      <Typography className={classes.subText}>
        To share your wishlist, just post your personal link to social or send it directly to that someone special. And
        don’t forget to make room on your doorstep!
      </Typography>
    </div>
  );

  const howItWorksStep3Text = (
    <div>
      <Typography className={classes.text}>3. Receive Your Gifts</Typography>
      <Typography className={classes.subText}>
        When you use Gift.me, you never have to worry about your safety. We understand the importance of your privacy.
        That’s why we’ll <span className={classes.textBold}>never share any of your personal information</span> with
        your fans.
      </Typography>

      <Typography className={classes.subText}>
        Packages arrive right to your door with complete discretion. Labels will only list Gift.me, RealGifts, or the
        vendor, and your gift-givers will never know your address.
      </Typography>
      <Typography className={classes.subText}>
        If you have more questions, check out our FAQs, and get ready to receive the gifts that make you feel like a
        million bucks.
      </Typography>
    </div>
  );

  return (
    <Box className={classes.root}>
      <HowItWorksHeader title={"How it works"} />
      <HowItWorksItem
        alt="Receive"
        src="/assets/howItWorks/how_it_works_1.png"
        style={{
          display: isDesktop ? "flex" : "block",
          margin: 0
        }}
        imgStyle={{
          width: "100%",
          objectFit: "cover",
          paddingRight: isDesktop ? "30px" : 0,
          paddingLeft: 0
        }}
      >
        <Box
          style={{
            width: isDesktop ? "50%" : "100%",
            padding: isDesktop ? "50px" : 0,
            display: "flex"
          }}
        >
          {howItWorksStep1Text}
        </Box>
      </HowItWorksItem>
      <HowItWorksItem
        alt="Receive"
        src="/assets/howItWorks/how_it_works_2.png"
        style={{
          display: isDesktop ? "flex" : "block",
          margin: isDesktop ? "0" : "50px 0px",
          flexDirection: "row-reverse"
        }}
        imgStyle={{
          width: "100%",
          objectFit: "cover",
          paddingRight: isDesktop ? "30px" : 0,
          paddingLeft: 0
        }}
      >
        <Box
          style={{
            width: isDesktop ? "50%" : "100%",
            padding: isDesktop ? "50px" : 0,
            display: "flex"
          }}
        >
          {howItWorksStep2Text}
        </Box>
      </HowItWorksItem>
      <HowItWorksItem
        alt="Receive"
        src="/assets/howItWorks/how_it_works_3.png"
        style={{
          display: isDesktop ? "flex" : "block",
          margin: isDesktop ? "0" : "50px 0px"
        }}
        imgStyle={{
          width: "100%",
          objectFit: "cover",
          paddingRight: isDesktop ? "30px" : 0,
          paddingLeft: 0
        }}
      >
        <Box
          style={{
            width: isDesktop ? "50%" : "100%",
            padding: isDesktop ? "50px" : 0,
            display: "flex"
          }}
        >
          {howItWorksStep3Text}
        </Box>
      </HowItWorksItem>
      <HowItWorksHeader title={"Browse our products"} />
      <Box textAlign="left" marginTop="25px">
        <PrimaryButton
          className={classes.addToWishlistButtonStyle}
          handleClick={() => {
            goTo("/store/storehome");
          }}
        >
          Browse products
        </PrimaryButton>
        <ScrollMenu
          wrapperStyle={{ marginLeft: isDesktop ? "unset" : -10, marginBottom: 50 }}
          menuClass={"horizontal-menu1"}
          wrapperClass={"menu-wrapper1"}
          data={menuItems}
          selected={selectedItem}
          onSelect={onSelect}
          hideArrows={true}
          alignCenter={false}
          itemClass={isDesktop ? "menu-item-wrapper-desktop1" : "menu-item-wrapper1"}
        />
      </Box>
    </Box>
  );
};

export default HowItWorks2;
