import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";
import TwitterLoginButton from "../authButtons/twitter/TwitterLoginButton";
import FacebookLoginButton from "../authButtons/facebook/FacebookLoginButton";
import GoogleLoginButton from "../authButtons/google/GoogleLoginButton";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../input/TextInputV2";
import theme from "../../theme";
import PrimaryButton from "../buttons/PrimaryButton";
import Form, { FormInput } from "../input/Form";
import axios from "axios";
import RollbarTracker from "../../utils/RollbarTracker";
import Toast from "light-toast";
import PrimaryLink from "../buttons/PrimaryLink";
import { checkEmailAction } from "../../actions/authAction";
import { isEmpty } from "../../utils/helpers";
import { setSignUpEvent } from "../../utils/localStorage";
import useNav from "../../hooks/useNav";
import { validateUserEmail } from "../../actions/userAction";
import InvalidEmailError from "../../errors/InvalidEmailError";
import DataLayer from "../../utils/dataLayer";
import { isTestENVSOnly, isLocalENVOnly, isTestQa } from "../../utils/appEnv";

const initialFormInputs = {
  email: FormInput(),
  otp: FormInput(),
  password: FormInput()
};

const LoginContentStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16)
  },
  title: {
    ...theme.typography.h6,
    fontSize: 19,
    marginBottom: "10px",
    maxWidth: "300px",
    textAlign: "center"
  },
  subtitle: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(8),
    fontSize: 14,
    textAlign: "center"
  },
  form: {
    textAlign: "center",
    maxWidth: 300
  },
  button: {
    marginBottom: theme.typography.pxToRem(8),
    fontSize: "14px"
  },
  emailButton: {
    width: "100%",
    height: "46px",
    borderRadius: "23px",
    padding: 0,
    background: "linear-gradient(135deg, #eb3693, #f0017c)",
    marginTop: theme.typography.pxToRem(12)
  },
  textField: {
    fontSize: 16,
    padding: "14px"
  },
  forgotPassword: {
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

interface LoginDialogContentProps {
  registration: boolean;
  returnTo: string;
}

const LoginDialogContent: React.FC<LoginDialogContentProps> = (props: LoginDialogContentProps) => {
  const contentStyles = LoginContentStyles();
  const { goTo } = useNav();
  const { registration, returnTo } = props;
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [emailCheckError, setEmailCheckError] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [isSignUp, setIsSignUp] = useState(registration);
  const toastMessageDuration = isTestQa() ? 7000 : 5000;

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("email" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : emailCheckError;
      }

      // if ('otp' === inputName) {
      //   formInput.error = formInput.value === ''
      //     ? 'This is required'
      //     : formInput.value.length < 4
      //       ? 'Please enter a 4 digit OTP'
      //       : '';
      // }
      if ("password" === inputName) {
        formInput.error =
          formInput.value.length !== 0 ? (formInput.value.length <= 3 ? "Password too short." : "") : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const { formInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(initialFormInputs, validateForm);

  const validateEmailLocal = async (): Promise<void> => {
    try {
      await validateEmail(formInputs.email.value);
      await getEmailErrorMsgOrSuggestedEmail(formInputs.email.value);
    } catch (error) {
      setEmailCheckError(error);
    }
  };

  const getEmailErrorMsgOrSuggestedEmail = async (email: string): Promise<void> => {
    try {
      const response = await checkEmailAction(email);

      // bypass smtp_check in test envs
      // TODO: Richard - smtpcheck is false a lot of the time, we are disabling it now, but we must investigate
      // const smptpCheck = isTestENVSOnly() ? true : response.data.smtp_check;
      const smptpCheck = true;

      if (response.data.error) {
        // Bypasses checkMail if returns error from Mailbox layer
        setEmailCheckError("");
        return;
      }

      if (!(response.data.mx_found && smptpCheck && response.data.format_valid)) {
        const errorMessage =
          response.data.did_you_mean && !isEmpty(response.data.did_you_mean)
            ? `Did you mean ${response.data.did_you_mean}`
            : "Please enter a valid email";

        throw new InvalidEmailError(errorMessage);
      }
    } catch (error) {
      if (error.name === "InvalidEmailError") {
        throw error.message;
      } else {
        throw "Error on checking email from Mailbox Layer API";
      }
    }
  };

  const validateEmail = async (email: string): Promise<void> => {
    try {
      const response = await validateUserEmail(email);
      if (!response.data.valid) {
        throw new InvalidEmailError(response.data.message);
      } else {
        setEmailCheckError("");
      }
    } catch (error) {
      if (error.name === "InvalidEmailError") {
        throw error.message;
      } else {
        throw "Error validating email address.";
      }
    }
  };

  const signInOrSignUp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    new DataLayer().gtag({
      key: "event",
      trackingId: "email_auth_click",
      config: {
        method: "email",
        page_path: window.location.pathname
      }
    });

    if (!isEmpty(emailCheckError)) {
      Toast.fail("Please enter a valid email address", toastMessageDuration);

      return;
    } else if (formInputs.password.value.length <= 3) {
      Toast.fail("Please enter a password with at least 4 characters", toastMessageDuration);

      return;
    }

    Toast.loading("Please wait...");

    interface SignInOrSignUpPayloadInterface {
      email: string;
      password: string;
      returnTo?: string;
      ref?: string;
    }

    const payload: SignInOrSignUpPayloadInterface = {
      email: formInputs.email.value.toLowerCase(),
      password: formInputs.password.value
    };

    if (returnTo) {
      payload.returnTo = returnTo;
    }

    const url_string = window.location.href;
    const url = new URL(url_string);
    const ref = url.searchParams.get("ref");

    if (ref) {
      payload.ref = ref;
    }

    axios
      .post("/api/auth/email", payload)
      .then((response) => {
        if (response.data.otpSent) {
          // Expects OTP
          console.log("New account created");
          Toast.info("Welcome, let's verify your email!", toastMessageDuration);

          setShowOtp(true);
        } else if (response.data.redirectTo) {
          // Sends GA join event on Signup
          if (isSignUp) {
            setSignUpEvent("email and password");
          }

          // Redirects when user exists
          console.log(
            "password authentication, redirecting to: " + "https://" + window.location.host + response.data.redirectTo
          );

          const redirectUrl = `${isLocalENVOnly() ? "http" : "https"}://${window.location.host}${
            response.data.redirectTo
          }`;
          const queryParams = response.data.newUser ? "?userFirstTimeLogin=1" : "";

          window.location.href = `${redirectUrl}${queryParams}`;
          localStorage.setItem("loggedInBefore", "true");

          // Set userFirstTimeLogin to check if user need to input handle/nickname
          if (response.data.newUser) {
            localStorage.setItem("userFirstTimeLogin", "1");
          }
        } else if (response.data.error) {
          Toast.info("Your password is incorrect, please try again.", toastMessageDuration);
        } else {
          Toast.info("Something went wrong, please try again later.", toastMessageDuration);
        }
      })
      .catch((error) => {
        Toast.info(error.response.data.message, toastMessageDuration, () => {
          Toast.hide();
        });
      });
  };

  const getOtp = () => {
    if (!isEmpty(emailCheckError)) {
      Toast.fail("Please enter a valid email address", toastMessageDuration);
      return;
    }

    Toast.loading("Please wait...");

    const postUrl = "/api/auth/forgot-password";

    const payload: any = {
      email: formInputs.email.value.toLowerCase()
    };

    axios
      .post(postUrl, payload)
      .then((response) => {
        console.log("getOtpForEmail - resp: ", response);
        Toast.hide();
        if (response.data.success) {
          // Expects OTP
          Toast.info("Success!", 250, () => {
            setShowOtp(true);
          });
        } else {
          Toast.fail("Something went wrong, please try again.", toastMessageDuration);
        }
      })
      .catch((error) => {
        const errorMsg = "Error password reset - getOtp";

        RollbarTracker.logError(errorMsg, error);
        console.log(errorMsg, error);

        if (error.response.status === 500) {
          goTo("/error");
        } else {
          Toast.fail(error.response.data.message, toastMessageDuration);
          Toast.hide();
        }
      });
  };

  const checkOtp = () => {
    if (formInputs.otp.value.length < 3) {
      Toast.fail("Your code is too short.");

      return;
    } else if (formInputs.password.value.length <= 3) {
      Toast.fail("Please enter a password with at least 4 characters", toastMessageDuration);

      return;
    }

    Toast.loading("Please wait...");

    const postUrl = "/api/auth/reset-password";

    const payload: any = {
      email: formInputs.email.value.toLowerCase(),
      password: formInputs.password.value,
      otp: formInputs.otp.value
    };

    if (returnTo) {
      payload.returnTo = returnTo;
    }

    axios
      .post(postUrl, payload)
      .then((response) => {
        console.log("checkEmailForOtp - resp: ", response);

        if (response.data.redirectTo) {
          console.log(
            "password authentication, redirecting to: " + "https://" + window.location.host + response.data.redirectTo
          );
          // Expects OTP
          Toast.info("Password updated! Welcome back.", toastMessageDuration, () => {
            window.location.href = "https://" + window.location.host + response.data.redirectTo;
            localStorage.setItem("loggedInBefore", "true");
          });
        } else {
          Toast.fail("Incorrect code, please try again.", toastMessageDuration);
        }
      })
      .catch((error) => {
        const errorMsg = "Error email auth - checkOtp";

        RollbarTracker.logError(errorMsg, error);
        console.log(errorMsg, error);

        if (error.response.status === 500) {
          goTo("/error");
        } else {
          Toast.fail(error.response.data.message, toastMessageDuration);
        }
      });
  };

  const socialAuthClick = (href, authType) => {
    new DataLayer().gtag({
      key: "event",
      trackingId: "social_auth_click",
      config: {
        method: authType,
        page_path: window.location.pathname
      }
    });

    if (isSignUp) {
      setSignUpEvent(authType);
    }

    let query = "";
    if (returnTo) {
      query = `?returnTo=${returnTo}`;
    }

    const url_string = window.location.href;
    const url = new URL(url_string);
    const ref = url.searchParams.get("ref");

    if (ref) {
      query = isEmpty(query) ? `?ref=${ref}` : query + `&ref=${ref}`;
    }

    window.location.href = href + query;
  };

  useEffect(() => {
    // Checks email error on reopening modal
    if (formInputs.email.value) {
      validateEmailLocal();
    }

    new DataLayer().gtag({
      key: "event",
      trackingId: "login_prompt",
      config: {
        page_path: window.location.pathname
      }
    });
  }, []);

  useEffect(() => {
    if (checkEmail) {
      validateEmailLocal();
    }

    setCheckEmail(false);
  }, [formInputs.email.value, checkEmail]);

  return (
    <Box className={contentStyles.root}>
      {showOtp ? (
        <>
          <Typography variant="subtitle1" className={contentStyles.subtitle}>
            We sent a code to your email!
          </Typography>
          <Typography variant="subtitle1" className={contentStyles.subtitle}>
            Please enter it below.
          </Typography>
          <TextInput
            id="otp"
            name="otp"
            label="Enter your code here"
            marginTop="16px"
            width="100%"
            InputLabelProps={{
              style: {
                fontSize: 16,
                lineHeight: 0.5
              }
            }}
            InputProps={{
              classes: {
                input: contentStyles.textField
              }
            }}
            onChange={handleInputChange}
            value={formInputs.otp.value}
            error={formInputs.otp.error}
            helperText={formInputs.otp.error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          <TextInput
            id="password"
            name="password"
            label={"New password"}
            marginTop="12px"
            width="100%"
            type="password"
            InputLabelProps={{
              style: {
                fontSize: 16,
                lineHeight: 0.5
              }
            }}
            InputProps={{
              classes: {
                input: contentStyles.textField
              }
            }}
            onChange={handleInputChange}
            value={formInputs.password.value}
            error={formInputs.password.error}
            helperText={formInputs.password.error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          <PrimaryButton className={contentStyles.emailButton} handleClick={() => checkOtp()}>
            Update Password
          </PrimaryButton>
        </>
      ) : showPasswordReset ? (
        <>
          <Typography variant="subtitle1" className={contentStyles.subtitle}>
            {"Let's verify your email."}
          </Typography>
          <Typography variant="subtitle1" className={contentStyles.subtitle}>
            Please enter it below.
          </Typography>
          <TextInput
            id="email"
            name="email"
            label="Email"
            marginTop="16px"
            width="100%"
            showErrorIcon={true}
            InputLabelProps={{
              style: {
                fontSize: 16,
                lineHeight: 0.5
              }
            }}
            InputProps={{
              classes: {
                input: contentStyles.textField
              }
            }}
            onChange={handleInputChange}
            value={formInputs.email.value}
            error={!isEmpty(emailCheckError) ? emailCheckError || formInputs.email.error : ""}
            helperText={!isEmpty(emailCheckError) ? emailCheckError || formInputs.email.error : ""}
            onFocus={handleOnFocus}
            onBlur={(e) => {
              setCheckEmail(true);
              handleOnBlur(e);
            }}
          />
          <PrimaryButton className={contentStyles.emailButton} handleClick={() => getOtp()}>
            Verify Email
          </PrimaryButton>
          <PrimaryLink
            text={"Go Back"}
            marginTop="8px"
            fontSize="13px"
            textColor="#333333"
            fontWeight="bold"
            handleClick={() => setShowPasswordReset(false)}
            href="#"
            underline="none"
          />
        </>
      ) : (
        <form onSubmit={signInOrSignUp} className={contentStyles.form}>
          <Typography style={{ marginTop: "10px" }} variant="subtitle1" className={contentStyles.subtitle}>
            Welcome to Gift.me
          </Typography>
          <Typography
            variant="h6"
            className={contentStyles.title}
            style={{ marginTop: "10px" }}
            data-cy="signup-header"
          >
            {isSignUp ? "Start Your Wishlist and Get What You Love" : "Sign In"}
          </Typography>
          <TextInput
            id="email"
            name="email"
            label="Email"
            marginTop="6px"
            cy-data="login-email"
            showErrorIcon={true}
            width="100%"
            InputLabelProps={{
              style: {
                fontSize: 16,
                lineHeight: 0.5
              }
            }}
            InputProps={{
              classes: {
                input: contentStyles.textField
              }
            }}
            onChange={handleInputChange}
            value={formInputs.email.value}
            error={!isEmpty(emailCheckError) ? emailCheckError || formInputs.email.error : ""}
            helperText={!isEmpty(emailCheckError) ? emailCheckError || formInputs.email.error : ""}
            onFocus={handleOnFocus}
            onBlur={(e) => {
              setCheckEmail(true);
              handleOnBlur(e);
            }}
          />
          <TextInput
            id="password"
            name="password"
            label={isSignUp ? "Create password" : "Password"}
            marginTop="12px"
            width="100%"
            type="password"
            cy-data="login-password"
            InputLabelProps={{
              style: {
                fontSize: 16,
                lineHeight: 0.5
              }
            }}
            InputProps={{
              classes: {
                input: contentStyles.textField
              }
            }}
            onChange={handleInputChange}
            value={formInputs.password.value}
            error={formInputs.password.error}
            helperText={formInputs.password.error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          {isSignUp ? null : (
            <Box display="flex" width={"100%"} justifyContent="center" marginTop="5px">
              <PrimaryLink
                className={contentStyles.forgotPassword}
                text="Forgot your password?"
                marginBottom="4px"
                fontSize="13px"
                textColor="#333333"
                fontWeight="bold"
                handleClick={() => setShowPasswordReset(true)}
                href="#"
                underline="none"
              />
            </Box>
          )}
          <PrimaryButton className={contentStyles.emailButton} type="submit" cy-data="signin-submit-btn">
            {isSignUp ? "Sign Up" : "Sign In"}
          </PrimaryButton>
          <Typography
            variant="subtitle1"
            className={contentStyles.subtitle}
            style={{
              marginTop: theme.typography.pxToRem(16),
              marginBottom: theme.typography.pxToRem(16)
            }}
          >
            OR
          </Typography>
          <FacebookLoginButton
            label={isSignUp ? "Sign Up" : "Sign In"}
            className={contentStyles.button}
            onClick={() => socialAuthClick("/auth/facebook", "facebook")}
          />
          <TwitterLoginButton
            label={isSignUp ? "Sign Up" : "Sign In"}
            className={contentStyles.button}
            onClick={() => socialAuthClick("/auth/twitter", "twitter")}
          />
          <GoogleLoginButton
            label={isSignUp ? "Sign Up" : "Sign In"}
            className={contentStyles.button}
            onClick={() => socialAuthClick("/auth/google", "google")}
          />
          <PrimaryLink
            text={isSignUp ? "Already a member? Log in" : "Don't have an account? Sign up"}
            marginTop="10px"
            fontSize="15px"
            textColor="#333333"
            fontWeight="bold"
            handleClick={() => setIsSignUp(!isSignUp)}
            href="#"
            underline="none"
            cy-data="signin-btn"
          />
        </form>
      )}
      <TermsAndConditions mainFontSize="12px" linkFontSize="12px" />
    </Box>
  );
};

export default LoginDialogContent;
