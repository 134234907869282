import React, { useContext, useEffect, useState } from "react";
import AppDialog from "../common/dialog/AppDialog";
import AppDialogMobile from "../common/dialog/AppDialogMobile";
import StoreProductContainerDesktop from "./StoreProductContainerDesktop";
import StoreProductContainerMobile from "./StoreProductContainerMobile";
import { useMediaQuery, MuiThemeProvider } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { UserContext } from "../../context";
import RollbarTracker from "../../utils/RollbarTracker";
import { checkoutWithGift } from "../gift/GiftUtils";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { decrementUserWishlistCount, incrementUserWishlistCount } from "../../reducers/user/UserSlice";
import { addWishlist, removeWishlist } from "../../actions/wishlistAction";
import { useApp } from "../../context/app-context";
import theme from "../../theme";
import { MY_ACCOUNT_PATH } from "../../constants/paths";

const StoreModal = (props) => {
  const dispatch = useAppDispatch();
  const { open, onClose, gift, setPromptLogin } = props;
  const appState = useApp();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [encryptedUserId, setEncryptedUserId] = useState(appState.encryptedUserId);

  const context = useContext(UserContext);
  const { email, nickname, gender } = context.user.get;
  const isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : false;

  const history = useHistory();

  const addGiftToWishlist = async (e) => {
    e.stopPropagation();

    if (encryptedUserId === undefined) {
      // todo - returnTo should go back to this page
      props.setPromptLogin({
        promptLogin: true,
        returnTo: undefined,
        registration: false
      });
      return;
    } else if (!isProfileComplete) {
      history.push(MY_ACCOUNT_PATH);
      return;
    }

    if (gift.is_in_user_wishlist) return;

    updateGiftWishlistState(true);
    dispatch(incrementUserWishlistCount());

    try {
      await addWishlist(gift);
    } catch (error) {
      dispatch(decrementUserWishlistCount());
      updateGiftWishlistState(false);
      RollbarTracker.logError("Error adding gift to wishlist", error);
      console.log("error: " + error);
      if (error.response.status === 500) {
        props.history.push("/error");
      }
    }
  };

  const removeGiftFromWishlist = async (e) => {
    e.stopPropagation();
    if (!gift.is_in_user_wishlist) return;

    updateGiftWishlistState(false);
    dispatch(decrementUserWishlistCount());
    try {
      await removeWishlist(gift);
    } catch (error) {
      dispatch(incrementUserWishlistCount());
      updateGiftWishlistState(true);
      RollbarTracker.logError("Error removing gift from wishlist", error);
      console.log("error: " + error);
      if (error.response.status === 500) {
        props.history.push("/error");
      }
    }
  };

  const updateGiftWishlistState = (addedToWishlist) => {
    gift.is_in_user_wishlist = addedToWishlist;
  };

  const handleBuyGift = async (e) => {
    e.stopPropagation();

    if (encryptedUserId && !isProfileComplete) {
      history.push(MY_ACCOUNT_PATH);
      return;
    }

    await checkoutWithGift(history, gift);
  };

  useEffect(() => {
    if (props.encryptedUserId) {
      setEncryptedUserId(props.encryptedUserId);
    }
  });

  if (isMobile) {
    return (
      <MuiThemeProvider theme={theme}>
        <AppDialogMobile
          open={open}
          onClose={onClose}
          fullWidth={false}
          title="Example"
          noHeaderLogo={true}
          isNotTransparent={true}
        >
          <StoreProductContainerMobile
            gift={gift}
            setPromptLogin={setPromptLogin}
            encryptedUserId={encryptedUserId}
            setWishlistCount={props.setWishlistCount}
            addGiftToWishlist={addGiftToWishlist}
            removeGiftFromWishlist={removeGiftFromWishlist}
            handleBuyGift={handleBuyGift}
            isMyWishlist={props.isMyWishlist}
          />
        </AppDialogMobile>
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <AppDialog
        open={open}
        onClose={onClose}
        fullWidth={false}
        title="Example"
        noHeaderLogo={true}
        isNotTransparent={true}
      >
        <StoreProductContainerDesktop
          gift={gift}
          setPromptLogin={setPromptLogin}
          encryptedUserId={encryptedUserId}
          setWishlistCount={props.setWishlistCount}
          addGiftToWishlist={addGiftToWishlist}
          removeGiftFromWishlist={removeGiftFromWishlist}
          handleBuyGift={handleBuyGift}
          isMyWishlist={props.isMyWishlist}
        />
      </AppDialog>
    </MuiThemeProvider>
  );
};

export default StoreModal;
