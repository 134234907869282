import React from "react";
import TextInput from "./TextInput";

export default function ZipCodeInput(props: any) {
  return (
    <TextInput
      id={props.id}
      name={props.name}
      label={props.label}
      type={props.type}
      autoComplete={props.autoComplete}
      style={{
        width: "100px",
        ...props.style
      }}
      marginTop={props.marginTop}
      maxLength="5"
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      disabled={props.disabled}
      className={props.className}
    />
  );
}
