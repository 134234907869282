import React, { useMemo, useState } from "react";
import Toast from "light-toast";
import { transferUserCredits } from "../../../actions/userAction";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { UserAccountSync } from "models/UserAccountSync";
import SecondaryButtonV2 from "components/buttons/SecondaryButtonV2";
import { LoadingState } from "../ProfileComponents";
import { COLORS } from "style";
import { roundToDecimalPlaces } from "utils/stringUtils";

interface AccountSyncRowProps {
  accountSync: UserAccountSync;
  isSynced: boolean;
  styles?: any;
  setIsAccountSyncing: (isSyncing: boolean) => void;
  disableOverride?: boolean;
}

const componentStyles = makeStyles(() => ({
  img: {
    width: 15,
    height: 15
  }
}));

const AccountSyncRow: React.FC<AccountSyncRowProps> = ({ accountSync, styles, setIsAccountSyncing, disableOverride }) => {
  const classes = componentStyles();

  const [isSynced, setIsSynced] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<number>(accountSync.credit_balance);

  const syncAccount = async () => {
    setIsSyncing(true);
    setIsAccountSyncing(true);
    try {
      await transferUserCredits({
        sourceChannelPartnerId: accountSync.channel_partner_id,
        sourceUserId: accountSync.user_id
      });
      setIsSynced(true);
      setCreditAmount(0);
    } catch (err) {
      Toast.fail("Failed to sync account");
    } finally {
      setIsSyncing(false);
      setIsAccountSyncing(false);
    }
  };

  const canSync = useMemo(() => {
    return creditAmount > 0;
  }, [creditAmount]);

  return (
    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", maxWidth: 600, ...styles }}>
      <img
        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${accountSync.channel_partner_url}`}
        alt=""
        className={classes.img}
      />
      <Typography variant="body2" style={{ marginLeft: 8 }}>
        {accountSync.channel_partner_store_name}
      </Typography>
      <Typography style={{ color: canSync ? COLORS.PINK : undefined }}>
        &nbsp;{"- $" + roundToDecimalPlaces(creditAmount.toString(), 2)}
      </Typography>
      <Typography variant="body1">&nbsp;credit.</Typography>
      <Box style={{ flex: 1 }} />
      <Box
        style={{
          display: "flex",
          maxWidth: 128,
          minWidth: 128,
          alignItems: "center",
          justifyItems: "center",
          justifyContent: "center"
        }}
      >
        {isSyncing ? (
          <LoadingState size={34} />
        ) : isSynced ? (
          <Typography variant="body1">Synced</Typography>
        ) : (
          <SecondaryButtonV2 text="Sync Account" disabled={!canSync || disableOverride} handleClick={syncAccount} />
        )}
      </Box>
    </Box>
  );
};

export default AccountSyncRow;
