import React from "react";
import { Typography, Box, FormControl } from "@material-ui/core";
import TextInputV3 from "../../input/TextInputV3";
import GenderSelector from "../../input/GenderSelector";
import BirthdaySelector from "../../input/BirthdaySelector";
import VanityUrl from "../../VanityUrl/VanityUrl";
import { makeStyles } from "@material-ui/core/styles";
import { SectionHeaderTitle } from "../ProfileComponents";

const componentStyles = makeStyles(() => ({
  root: {},
  input: {
    marginBottom: 24
  }
}));

interface AccountDetailsPersonalInformationProps {
  nickname: any;
  gender: any;
  email: any;
  birthYear: any;
  birthMonth: any;
  birthDay: any;
  handleInputChange: (e: any) => void;
  handleOnFocus: (e: any) => void;
  handleOnBlur: (e: any) => void;
  vanityUrlOnChangedHandler: (vanity: string, error: string) => void;
  validateNickname: (nickname: string) => void;
}

const AccountDetailsPersonalInformation: React.FC<AccountDetailsPersonalInformationProps> = (
  props: AccountDetailsPersonalInformationProps
) => {
  const {
    nickname,
    email,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    handleInputChange,
    handleOnFocus,
    handleOnBlur,
    validateNickname,
    vanityUrlOnChangedHandler
  } = props;
  const classes = componentStyles();
  return (
    <Box>
      <SectionHeaderTitle text="Your Info" />
      <FormControl fullWidth style={{ marginTop: "24px" }}>
        <TextInputV3
          id="nickname"
          name="nickname"
          label="Handle"
          value={nickname.value}
          error={nickname.error}
          helperText={nickname.error}
          onBlur={(e) => {
            handleOnBlur(e);
            validateNickname(e.target.value);
          }}
          onChange={(e) => {
            handleInputChange(e);
          }}
          onFocus={handleOnFocus}
          className={classes.input}
        />
        <TextInputV3
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          label="Your Email Address"
          value={email.value}
          error={email.error}
          helperText={email.error}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          className={classes.input}
        />
        <GenderSelector
          value={gender.value}
          error={gender.error}
          helperText={gender.error}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
        />

        <Box>
          <BirthdaySelector
            values={{
              birthYear: birthYear.value,
              birthMonth: birthMonth.value,
              birthDay: birthDay.value
            }}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
          />
        </Box>
        <VanityUrl vanityUrlOnChangedHandler={vanityUrlOnChangedHandler} />
      </FormControl>
    </Box>
  );
};

export default AccountDetailsPersonalInformation;
