import React, { useMemo } from "react";
import { Grid, useMediaQuery, Theme, makeStyles, Box } from "@material-ui/core";
import Section from "../common/Section";
import PrimaryButtonV2 from "../common/PrimaryButtonV2";
import { COLORS } from "../../../style";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH } from "../../../constants/paths";

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    flexDirection: "row",
    textAlign: "center",
    paddingBottom: 103,
    paddingTop: 104,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
      paddingBottom: 80
    }
  },
  boxCategory: {
    background: COLORS.FLESH,
    "& > img": {
      cursor: "pointer",
      width: "100%"
    }
  }
}));

const CategoriesSection = () => {
  const { goTo } = useNav();
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const dir = "/assets/home/homeV4/categories/";

  const categories = useMemo(
    () => [
      {
        title: "Popular",
        url: "/store/store/407569/☆%20Popular%20☆",
        storeId: 0
      },
      {
        title: "Gucci",
        url: "/store/store/2879204/Gucci",
        storeId: 0
      },
      {
        title: "Handbags",
        url: "/store/store/1748826/Bags%20&%20Wallets",
        storeId: 0
      },
      {
        title: "Fendi",
        url: "/store/store/2878909/Fendi",
        storeId: 0
      },
      {
        title: "Heels",
        url: "/store/store/1748846/Heels%20&%20Sandals",
        storeId: 0
      },
      {
        title: "Fragrances",
        url: "/store/store/129730/Fragrances",
        storeId: 0
      },

      {
        title: "Diamonds",
        url: "/store/store/114293/Diamonds",
        storeId: 0
      },
      {
        title: "Watches",
        url: "/store/store/5376251/Timepieces",
        storeId: 0
      },
      {
        title: "Sunglasses",
        url: "/store/store/5376248/Sunglasses",
        storeId: 0
      },
      {
        title: "Flowers",
        url: "/store/store/1742068/Floral",
        storeId: 0
      },
      {
        title: "Swimwear",
        url: "/store/store/1756525/Swimwear",
        storeId: 0
      },
      {
        title: "Chocolates",
        url: "store/store/5427903/Chocolates",
        storeId: 0
      }
    ],
    []
  );

  const renderDesktop = () => {
    return categories.map((item, idx) => (
      <Grid item xs={6} lg={3} key={idx}>
        <Box className={classes.boxCategory}>
          <img onClick={() => goTo(item.url)} src={`${dir}${item.title}.jpg`} />
        </Box>
      </Grid>
    ));
  };

  return (
    <Section bgcolor="#1D1D1D" contentProps={{ className: classes.sectionContent }}>
      <>
        <Grid container spacing={4}>
          {renderDesktop()}
        </Grid>
        <PrimaryButtonV2
          label="See More Categories"
          onClick={() => goTo(STOREHOME_PATH)}
          style={{ width: 219, height: isDesktop ? 48 : 40, marginTop: 64 }}
        />
      </>
    </Section>
  );
};

export default CategoriesSection;
