import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
  Theme,
  useMediaQuery
} from "@material-ui/core";
import { roundToDecimalPlaces } from "../../utils/stringUtils";
import { CURRENCY } from "../common/Constants";

const useStyles = makeStyles(() => ({
  th: {
    borderBottom: "none",
    padding: "7px 16px"
  }
}));

function TableRowComponent(props) {
  const styles = useStyles();
  const { title, value, thStyles, valueStyles } = props;
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={styles.th} style={thStyles}>
        <Typography component="span" variant="body1" style={valueStyles}>
          {title}
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        className={styles.th}
        style={{
          ...thStyles,
          textAlign: "right"
        }}
      >
        <Typography component="span" variant="body1" style={valueStyles}>
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export function GiftSummary(props) {
  const { checkoutData, gift, receiverNickname } = props;
  const itemCost = CURRENCY + roundToDecimalPlaces(props.price, 2);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  console.log("THIS IS THE CHECKOUT DATA: ", checkoutData);
  const doesUserHaveCredit = useMemo(
    () => checkoutData && checkoutData.credit_amount_converted && checkoutData.credit_amount_converted > 0,
    [checkoutData]
  );
  const secondLastCellStyle = { padding: "7px 16px 14px 16px", whiteSpace: "nowrap" };
  const taxStyle = doesUserHaveCredit ? {} : secondLastCellStyle;

  return (
    <TableContainer>
      {!isDesktop && (
        <div style={{ marginBottom: 10 }}>
          <Typography component="span" style={{ padding: "7px 16px" }}>
            Ship gift to: <b>{receiverNickname}</b>
          </Typography>
        </div>
      )}
      <Table aria-label="customized table">
        <TableBody>
          <TableRowComponent title={`${gift.name.replace("&apos;", "'")}:`} value={itemCost} />
          <TableRowComponent
            title="Delivery:"
            value={
              CURRENCY +
              roundToDecimalPlaces(
                checkoutData ? checkoutData.shipping_price_converted : gift.pricing.shipping_price_converted,
                2
              )
            }
          />
          <TableRowComponent
            title="Tax/Fees:"
            value={
              CURRENCY +
              roundToDecimalPlaces(checkoutData ? checkoutData.tax_price_dollars : gift.pricing.tax_price_dollars, 2)
            }
            thStyles={taxStyle}
          />
          {doesUserHaveCredit ? (
            <TableRowComponent
              title="Credit:"
              value={"-" + CURRENCY + roundToDecimalPlaces(checkoutData.credit_amount_converted, 2)}
              thStyles={secondLastCellStyle}
            />
          ) : null}
          <TableRowComponent
            title="Order total:"
            value={
              CURRENCY +
              roundToDecimalPlaces(
                checkoutData ? checkoutData.total_price_converted : gift.pricing.total_price_converted,
                2
              )
            }
            thStyles={{
              backgroundColor: "rgba(0,0,0,0.1)",
              borderBottom: "none",
              padding: "14px 16px"
            }}
            valueStyles={{
              fontWeight: "bold"
            }}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
