import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import AppDialog from "../common/dialog/AppDialog";
import ReceivedAGiftDialogContent from "./ReceivedAGiftDialogContent";
import ExchangeForCreditDialogContent from "./ExchangeForCreditDialogContent";
import GiftAcceptedDialogContent from "./GiftAcceptedDialogContent";
import AcceptStoreCreditDialogContent from "./AcceptStoreCreditDialogContent";
import StoreCreditAcceptedDialogContent from "./StoreCreditAcceptedDialogContent";
import DeclinedGiftAndCreditDialogContent from "./DeclinedGiftAndCreditDialogContent";
import ConfirmGiftShippingAddressContent from "./ConfirmGiftShippingAddressContent";
import GiftRedeemedDialogContent from "./GiftRedeemedDialogContent";
import RollbarTracker from "../../utils/RollbarTracker";
import axios from "axios";
import { RedeemDialogStyle } from "./RedeemGiftFlowStyles";
import useNav from "../../hooks/useNav";
import { ERROR_PATH } from "../../constants/paths";
import { exchangeGiftForCredit } from "../../actions/giftAction";
import Toast from "light-toast";
import { useApp } from "../../context/app-context";
import { ORDER_STATUSES } from "../../actions/orderAction";

const PAGE_RECEIVED_A_GIFT = "PAGE_RECEIVED_A_GIFT";
const PAGE_EXCHANGE_GIFT_FOR_STORE_CREDIT = "PAGE_EXCHANGE_GIFT_FOR_STORE_CREDIT";
const PAGE_GIFT_ACCEPTED = "PAGE_GIFT_ACCEPTED";
const PAGE_ACCEPT_STORE_CREDIT = "PAGE_ACCEPT_STORE_CREDIT";
const PAGE_STORE_CREDIT_ACCEPTED = "PAGE_STORE_CREDIT_ACCEPTED";
const PAGE_GIFT_AND_CREDIT_DECLINED = "PAGE_GIFT_AND_CREDIT_DECLINED";
const PAGE_GIFT_CONFIRM_SHIPPING_ADDRESS = "PAGE_GIFT_CONFIRM_SHIPPING_ADDRESS";
const PAGE_GIFT_REDEEMED = "PAGE_GIFT_REDEEMED";

interface RedeemGiftFlowProps {
  open: boolean;
  onClose: () => void;
  orderId: string;
}

const RedeemGiftFlow: React.FC<RedeemGiftFlowProps> = (props: RedeemGiftFlowProps) => {
  const { open, onClose, orderId } = props;

  const [page, setPage] = useState(PAGE_RECEIVED_A_GIFT);
  const [order, setOrder] = useState(null);
  const [giftOptions, setGiftOptions] = useState([]);
  const [claimAddress, setClaimAddress] = useState(null);
  const [buyerNickname, setBuyerNickname] = useState();
  const [redeemingUser, setRedeemingUser] = useState(undefined);
  const [encryptedUserId, setEncryptedUserId] = useState(undefined);
  const [openModal, setOpenModal] = useState(open);
  const { goTo } = useNav();
  const { setPromptLogin } = useApp();

  const fetchUser = () => {
    axios
      .get("/api/user/")
      .then((response) => {
        setOpenModal(true);
        setRedeemingUser({
          ...response.data
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // Handle 401
          console.log("no user, performing login");
          setOpenModal(false);
          // todo - make this redirect back to original URL to complete redeem flow
          setPromptLogin({
            promptLogin: true,
            returnTo: "/store/storehome/" + orderId,
            registration: false
          });
          return;
        }
        if (error.response.status === 500) {
          goTo(ERROR_PATH);
        }
      });
  };

  const fetchEncryptedUserId = () => {
    axios
      .get("/api/user/id")
      .then((response) => {
        setEncryptedUserId(response.data.encryptedUserId);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          goTo(ERROR_PATH);
        }
      });
  };

  const getOrder = async () => {
    try {
      const { data } = await axios.get(`/api/getOrders?order_id=${orderId}`);
      if (data.order.length > 0) {
        const orderData = data.order[0];
        if (orderData.receiver_id !== redeemingUser.userId) {
          goTo(ERROR_PATH, {
            errorMessage:
              "Oops! Looks like this gift was intended for someone else.<br/> Please log into the account this gift was sent to in order to complete gift redemption."
          });
        }

        if (ORDER_STATUSES.UNCLAIMED !== orderData.claim_status.toLowerCase()) {
          goTo(ERROR_PATH, {
            errorMessage: "Oops! It looks like this gift was already redeemed."
          });
        }

        setOrder(orderData);
      }
      if (data.buyer_nickname) {
        setBuyerNickname(data.buyer_nickname);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) {
        const {
          error: { message }
        } = error.response.data;
        goTo(ERROR_PATH, { errorMessage: message });
      }
    }
  };

  const showReceivedGiftPage = () => {
    setPage(PAGE_RECEIVED_A_GIFT);
  };

  const onExchangeForCreditClicked = async () => {
    try {
      await exchangeGiftForCredit(order.order_id);
      setPage(PAGE_STORE_CREDIT_ACCEPTED);
    } catch (error) {
      Toast.fail(error.response.data.message, 3000);
    }
  };

  const onKeepGiftClicked = () => {
    setPage(PAGE_GIFT_ACCEPTED);
  };

  const onDeclineGiftAndCreditClicked = () => {
    setPage(PAGE_GIFT_AND_CREDIT_DECLINED);
  };

  const onGiftDeclined = () => {
    setPage(PAGE_GIFT_AND_CREDIT_DECLINED);
  };

  const onOpenWishlistClicked = () => {
    goTo("/gift/wishlist/" + encryptedUserId);
  };

  const onEnteredShippingAddress = (claimAddress) => {
    setClaimAddress(claimAddress);
    setPage(PAGE_GIFT_CONFIRM_SHIPPING_ADDRESS);
  };

  const onConfirmShippingAddressClicked = () => {
    // Partial gift_options format
    const formatSelectedGiftOptions = {};
    giftOptions.map((item) => {
      formatSelectedGiftOptions[`${item.name}_name`] = item.label;
      formatSelectedGiftOptions[`${item.name}_value`] = item.value;
    });
    const delivery_phone =
      claimAddress.delivery_country === "US" && claimAddress.delivery_phone.length === 10
        ? `+1${claimAddress.delivery_phone}`
        : claimAddress.delivery_phone;

    axios
      .post("/api/gifts/claim", {
        order_id: order.order_id,
        gift_options: formatSelectedGiftOptions,
        delivery_email: claimAddress.delivery_email,
        delivery_first_name: claimAddress.delivery_first_name,
        delivery_last_name: claimAddress.delivery_last_name,
        delivery_address: claimAddress.delivery_address,
        delivery_city: claimAddress.delivery_city,
        delivery_state: claimAddress.delivery_state || claimAddress.delivery_province,
        delivery_zip: claimAddress.delivery_zip,
        delivery_country: claimAddress.delivery_country,
        delivery_phone
      })
      .then((response) => {
        console.log("gift claimed = ", response);
        setPage(PAGE_GIFT_REDEEMED);
      })
      .catch((error) => {
        RollbarTracker.logError("Error claiming gift", error);
        console.log("error: ", error);
        if (error.response.status === 500) {
          const {
            error: { message }
          } = error.response.data;
          goTo(ERROR_PATH, { errorMessage: message });
        }
      });
  };

  useEffect(() => {
    if (!redeemingUser) {
      setOpenModal(false);
    } else {
      setOpenModal(open);
    }
  }, [open, redeemingUser]);

  let title;
  let content;

  if (buyerNickname === undefined || order === undefined) {
    title = "Loading";
    content = (
      <Box height={150} display="flex" justifyContent="center">
        <CircularProgress style={{ marginTop: "50px" }} />
      </Box>
    );
  } else if (page === PAGE_RECEIVED_A_GIFT) {
    title = "You have received a gift!";
    content = (
      <ReceivedAGiftDialogContent
        order={order}
        buyerNickname={buyerNickname}
        onAcceptGiftClicked={onKeepGiftClicked}
        onGiftDeclined={onGiftDeclined}
        onExchangeForCreditClicked={onExchangeForCreditClicked}
      />
    );
  } else if (page === PAGE_EXCHANGE_GIFT_FOR_STORE_CREDIT) {
    title = "Exchange?";
    content = (
      <ExchangeForCreditDialogContent
        order={order}
        onExchangeForCreditClicked={onExchangeForCreditClicked}
        onKeepGiftClicked={onKeepGiftClicked}
      />
    );
  } else if (page === PAGE_GIFT_ACCEPTED) {
    title = "You have accepted a gift!";
    content = (
      <GiftAcceptedDialogContent
        order={order}
        claimAddress={claimAddress}
        redeemingUser={redeemingUser}
        onEnteredGiftOptions={(options) => setGiftOptions(options)}
        onEnteredShippingAddress={onEnteredShippingAddress}
        onGoBackClicked={showReceivedGiftPage}
      />
    );
  } else if (page === PAGE_ACCEPT_STORE_CREDIT) {
    title = "Store Credit?";
    content = (
      <AcceptStoreCreditDialogContent
        order={order}
        onExchangeForCreditClicked={onExchangeForCreditClicked}
        onDeclineGiftAndCreditClicked={onDeclineGiftAndCreditClicked}
      />
    );
  } else if (page === PAGE_STORE_CREDIT_ACCEPTED) {
    title = "You have exchanged this gift";
    content = (
      <StoreCreditAcceptedDialogContent order={order} onOpenWishlistClicked={onOpenWishlistClicked} onClose={onClose} />
    );
  } else if (page === PAGE_GIFT_AND_CREDIT_DECLINED) {
    title = "You have declined this gift";
    content = (
      <DeclinedGiftAndCreditDialogContent
        order={order}
        onOpenWishlistClicked={onOpenWishlistClicked}
        onClose={onClose}
      />
    );
  } else if (page === PAGE_GIFT_CONFIRM_SHIPPING_ADDRESS) {
    title = "Confirm your shipping address";
    content = (
      <ConfirmGiftShippingAddressContent
        order={order}
        claimAddress={claimAddress}
        giftOptions={giftOptions}
        onConfirmShippingAddressClicked={onConfirmShippingAddressClicked}
        onGoBackClicked={onKeepGiftClicked}
      />
    );
  } else if (page === PAGE_GIFT_REDEEMED) {
    title = "Your gift will be delivered soon!";
    content = (
      <GiftRedeemedDialogContent
        order={order}
        claimAddress={claimAddress}
        giftOptions={giftOptions}
        buyerNickname={buyerNickname}
        onOpenWishlistClicked={onOpenWishlistClicked}
      />
    );
  }

  useEffect(() => {
    if (orderId) {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (redeemingUser) {
      if (!order && orderId) {
        getOrder();
      }
      if (encryptedUserId === undefined) {
        fetchEncryptedUserId();
      }
    }
  }, [redeemingUser, order, orderId]);

  return (
    <RedeemGiftDialog open={openModal} onClose={onClose} title={title}>
      {content}
    </RedeemGiftDialog>
  );
};

interface IRedeemGiftDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactElement;
}
function RedeemGiftDialog(props: IRedeemGiftDialogProps) {
  const { open, onClose, title, children } = props;
  const dialogStyles = RedeemDialogStyle();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      fullWidth={false}
      title={title}
      paperClassName={dialogStyles.paper}
      titleRootStyle={{
        display: "block",
        padding: "16px 52px 16px 16px"
      }}
    >
      {children}
    </AppDialog>
  );
}

export default RedeemGiftFlow;
