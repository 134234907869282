import React from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import AppLayout from "../common/appLayout/AppLayout";
import MyAccountMenu from "./MyAccountMenu";
import theme from "../../theme/theme-devins";
import Section from "../common/appLayout/Section";
import { FOOTER_VARIANT2 } from "../common/appFooter/FooterVariants";
import useMediaBreakpoints from "../../hooks/useMediaBreakpoints";
import { BACKGROUND, backgroundTheme } from "../../style";
import { LoadingState } from "./ProfileComponents";

export const ProfileContentContainerStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: "45%"
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "60%"
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "90%"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "7rem",
      "flex-basis": "70%"
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem"
    }
  },
  divider: {
    margin: "2rem auto"
  }
}));

interface IProfileContentContainerProps {
  children: React.ReactNode;
  page: string;
  isLoading?: boolean;
}
const ProfileContentContainer: React.FC<IProfileContentContainerProps> = ({
  children,
  page,
  isLoading
}: IProfileContentContainerProps) => {
  const { isMobile } = useMediaBreakpoints();

  return (
    <AppLayout
      theme={theme}
      headerVariant="header1"
      footerVariant={FOOTER_VARIANT2}
      backgroundColor={backgroundTheme.grayFill}
      style={{
        position: "relative"
      }}
    >
      <Section>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <MyAccountMenu isSelected={page} />
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{
              marginTop: isMobile ? "5rem" : "7rem",
              minHeight: "700px"
            }}
          >
            {isLoading ? <LoadingState /> : <>{children}</>}
          </Grid>
        </Grid>
      </Section>
    </AppLayout>
  );
};

export default ProfileContentContainer;
