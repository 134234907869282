import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, CircularProgress, MuiThemeProvider } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RedeemGiftFlow from "../redeemGift/RedeemGiftFlow";
import { replaceUnicodeChars } from "../../utils/stringUtils";
import { DESKTOP_CONTENT_FULL_WIDTH } from "../common/Constants";
import PostSignupDialog from "../postSignup/PostSignupDialog";
import AppFooter from "../common/appFooter/AppFooter";
import { SetPromptLoginInterface } from "../../interface/AppInterface";
import useNav from "../../hooks/useNav";
import { ERROR_PATH, STOREHOME_PATH } from "../../constants/paths";
import { useQuery } from "../../hooks/useQuery";
import Toast from "light-toast";
import { useAppSelector } from "../../hooks";
import StoreHomePhotoHeader from "./StoreHomePhotoHeader";
import theme from "../../theme/theme-devins";
import StoreHomeTabHeader from "./StoreHomeTabHeader";
import StoreHomeMenuSlider from "./StoreHomeMenuSlider";
import StoreHomeSections from "./StoreHomeSections";
import { StoreInterface } from "../../models/store";
import StoreHeader from "../store/StoreHeader";
import AppLayout from "../common/appLayout/AppLayout";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_FULL_WIDTH
    }
  },
  gridContainerMenu: {
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_FULL_WIDTH,
      padding: "104px 0px",
      maxWidth: 1280,
      margin: "auto"
    }
  },
  gridContainerItems: {
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_FULL_WIDTH,
      padding: "0px 70px 0px",
      maxWidth: 1440,
      margin: "auto"
    }
  },
  gridContainerFooter: {
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_FULL_WIDTH,
      padding: "0px 105px"
    }
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)"
  }
}));

interface StoreHomeV2Props {
  match: any;
  history: any;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
  loadedStores: Array<StoreInterface>;
}

const StoreHomeV2: React.FC<StoreHomeV2Props> = ({ match, setPromptLogin, loadedStores }: StoreHomeV2Props) => {
  const styles = useStyles();
  const { goTo } = useNav();
  const query = useQuery();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const { encryptedUserId, email, nickname, gender } = useAppSelector((state) => state.user.data);

  const [pageData, setState] = useState({
    page: 1,
    stores: [],
    orderId: match.params.orderId,
    redeemOpen: false,
    error: null
  });

  const [endOfPages, setEndOfPages] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    if (!endOfPages) {
      setIsFetching(true);
    }
  }

  const fetchStores = () => {
    axios
      .get(`/api/gifts/getstores?page=${pageData.page}&is_desktop=${isDesktop}`, {
        withCredentials: true
      })
      .then((response) => {
        const pageSize = isDesktop ? 10 : 30;
        const responseStoresArray = Object.values(response.data.stores);
        const sortedByRank = responseStoresArray.sort((a: any, b: any) => {
          return b.rank - a.rank;
        });
        if (responseStoresArray.length === 0) {
          setIsFetching(false);
          setEndOfPages(true);
          return;
        }

        // hack to remove "All Gifts"
        const filteredStoresResponse = sortedByRank.filter((store: any) => {
          return (
            store.name !== "All Gifts" && store.name !== "♡ Wish List ♡" && store.name !== "&#9825; Wish List &#9825;"
          );
        });

        const newStores = pageData.stores;

        filteredStoresResponse.map((store) => {
          newStores.push(store);
        });

        setState({
          ...pageData,
          stores: newStores,
          page: pageData.page + 1
        });

        setIsFetching(false);

        if (responseStoresArray.length < pageSize) {
          setEndOfPages(true);
        }
      })
      .catch((error) => {
        setState({
          ...pageData,
          error: error
        });
        if (error.response.status === 500) {
          goTo(ERROR_PATH);
        }
      });
  };

  useEffect(() => {
    if (!isFetching || endOfPages) return;
    fetchStores();
  }, [isFetching]);

  useEffect(() => {
    fetchStores();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const promptLogin = query.get("prompt-login");
    if (typeof promptLogin !== "undefined" && parseInt(promptLogin) === 1) {
      setPromptLogin({
        promptLogin: true,
        returnTo: STOREHOME_PATH,
        registration: false,
        forceRegistration: false
      });

      Toast.fail(query.get("error-message"), 5000, () => {
        Toast.hide();
      });
    }
  }, [query.get("prompt-login")]);

  useEffect(() => {
    if (endOfPages) {
      setState({
        ...pageData,
        redeemOpen: match.params.orderId !== undefined && encryptedUserId !== null ? true : false
      });
    }
  }, [match.params.orderId, encryptedUserId, endOfPages]);

  return (
    <Box style={{ backgroundColor: "#fff" }}>
      <AppLayout theme={theme} darkMode={false} hasFooter={false}>
        {pageData.stores.length === 0 ? (
          <Box minHeight={650} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className={styles.gridContainer}>
              <StoreHomePhotoHeader gender={gender} />
            </Box>
            <Box className={styles.gridContainer}>
              <StoreHomeTabHeader />
            </Box>
            <Box className={styles.gridContainerMenu}>
              <StoreHomeMenuSlider />
            </Box>
            <Box className={styles.gridContainerItems}>
              <StoreHomeSections gender={gender} />
            </Box>
            <Box className={styles.gridContainerFooter}>
              {isFetching && !endOfPages ? (
                <Box height={150} display="flex" justifyContent="center">
                  <CircularProgress style={{ marginTop: "50px" }} />
                </Box>
              ) : null}
            </Box>
          </>
        )}
        <RedeemGiftFlow
          orderId={pageData.orderId}
          open={pageData.redeemOpen}
          onClose={() => setState({ ...pageData, redeemOpen: false })}
        />
        <PostSignupDialog />
      </AppLayout>
    </Box>
  );
};

export default StoreHomeV2;
