import * as sdk from "../sdk";

export const ORDER_STATUSES = {
  UNCLAIMED: "unclaimed",
  CLAIMED: "claimed",
  EXCHANGED: "exchanged",
  DECLINED: "declined",
  WITHDRAWN: "withdrawn",
  EXPIRED: "expired",
  ABORTED: "aborted"
};

export enum orderParty {
  Buyer = "buyer",
  Receiver = "receiver"
}

export const getOrders = async (page: number, numOfItems: number, orderParty?: orderParty) => {
  try {
    return await sdk.getOrders(page, numOfItems, orderParty);
  } catch (error) {
    console.error("orderAction - ERROR FETCHING ORDERS");

    throw error;
  }
};

export const mapOrderStatus = (status: string): { label: string; color: string } | null => {
  const colors: { gray: string; red: string; green: string } = {
    gray: "#B5B5B5",
    red: "#DD0808",
    green: "#11C773"
  };

  const data = {
    unclaimed: {
      color: colors.gray,
      label: "PENDING"
    },
    claimed: {
      color: colors.green,
      label: "GIFT ACCEPTED"
    },
    exchanged: {
      color: colors.green,
      label: "EXCHANGED"
    },
    declined: {
      color: colors.red,
      label: "DECLINED"
    },
    withdrawn: {
      color: colors.red,
      label: "WITHDRAWN"
    },
    expired: {
      color: colors.red,
      label: "EXPIRED"
    },
    aborted: {
      color: colors.red,
      label: "ABORTED"
    }
  };

  return data[status] ?? null;
};
