import React from "react";
import GiftResults from "../common/gift/GiftResults";
import GiftModal from "../common/gift/GiftModal";
import { GiftInterface } from "../../models/gift";
import { getGiftsAction } from "../../actions/giftAction";
import { StoreInterface } from "../../models/store";
import { SetPromptLoginInterface } from "../../interface/AppInterface";
import InfiniteScroll from "../common/InfiniteScroll";
import StoreHeader from "./StoreHeader";
import GiftShareModal from "../common/gift/GiftShareModal";
import { ERROR_PATH } from "../../constants/paths";
import theme from "../../theme/theme-devins";
import { backgroundTheme } from "../../style";
import { FOOTER_VARIANT2 } from "../common/appFooter/FooterVariants";
import AppLayout from "../common/appLayout/AppLayout";
import Section from "../common/appLayout/Section";

interface StorePropsInterface {
  match: any;
  history: any;
  loadedStores: Array<StoreInterface>;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
}

interface StoreStateInterface {
  isFetching: boolean;
  isFetchingNextPage: boolean;
  hasMoreData: boolean;
  isInitialLoad: boolean;
  productModalIsOpen: boolean;
  page: number;
  sort: string;
  gifts: Array<GiftInterface>;
  nextGifts: Array<GiftInterface>;
  currentGift: GiftInterface | null;
  storeId: string | number;
  storeName: string;
  openProductShareModal: boolean;
}

class Store extends React.Component<StorePropsInterface, StoreStateInterface> {
  storeId;
  storeName;

  constructor(props: StorePropsInterface) {
    super(props);
    this.state = {
      isFetching: false,
      isFetchingNextPage: true,
      hasMoreData: true,
      isInitialLoad: true,
      productModalIsOpen: false,
      page: 1,
      sort: "popular",
      gifts: [],
      nextGifts: [],
      currentGift: null,
      storeId: "",
      storeName: "",
      openProductShareModal: false
    };

    this.onBottomHit = this.onBottomHit.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onPageUpdated = this.onSortUpdated.bind(this);
    this.onSortUpdated = this.onSortUpdated.bind(this);
    this.onCurrentGift = this.onCurrentGift.bind(this);
    this.onProductModalIsOpen = this.onProductModalIsOpen.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onStoreChanged = this.onStoreChanged.bind(this);
    this.handleOnProductShareClicked = this.handleOnProductShareClicked.bind(this);
    this.onGiftShareModalClose = this.onGiftShareModalClose.bind(this);
  }

  formatGiftsResponse = (response: any): GiftInterface[] => {
    const gifts: Array<GiftInterface> = [];
    if (Object.values(response.data.gifts).length) {
      Object.values(response.data.gifts).map((gift: GiftInterface) => {
        // is this gift part of a group with a single item as the master item?
        if (gift.group_data.consolidated_group) {
          // is the gift the master item? (i.e - should it be shown in the store's gifts list?)
          if (gift.group_master_gift_id === gift.gift_id) {
            // it's the master item in the consolidated group, let's add it
            gifts.push(gift);
          }
        } else {
          gifts.push(gift);
        }
      });
    }
    return gifts;
  };

  async fetchGifts(): Promise<void> {
    if (this.state.isFetching) {
      return;
    }
    const { page, sort, storeId, nextGifts } = this.state;

    if (page === 1) {
      // show loading
      this.setState({
        gifts: [],
        nextGifts: [],
        isFetching: true,
        isFetchingNextPage: true
      });
    } else {
      this.setState((prevState) => {
        const prevGifts = prevState.gifts;
        const prevNexGifts = nextGifts;
        const updateGifts = prevGifts.concat(
          prevNexGifts.filter((next) => prevGifts.every((previous) => previous.gift_id !== next.gift_id))
        );
        return {
          ...prevState,
          gifts: updateGifts,
          isFetchingNextPage: true
        };
      });
    }

    const payload = {
      storeId,
      page,
      pageSize: 24,
      product_filters: { sort }
    };

    try {
      const gifts = this.formatGiftsResponse(await getGiftsAction(payload));
      // check for initial load [page = 1]
      if (page === 1) {
        // fetch page 2 ahead and store to nextGifts state
        const page = 2;
        const nextGifts = this.formatGiftsResponse(await getGiftsAction({ ...payload, page }));
        this.setState({
          isFetching: false,
          nextGifts,
          page,
          gifts,
          isInitialLoad: false,
          hasMoreData: nextGifts.length > 0,
          isFetchingNextPage: false
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            isFetching: false,
            nextGifts: gifts,
            isInitialLoad: false,
            hasMoreData: gifts.length > 0,
            isFetchingNextPage: false
          };
        });
      }
    } catch (error) {
      this.setState(() => {
        return {
          isFetching: false
        };
      });
      if (error.response.status === 500) {
        this.props.history.push(ERROR_PATH);
      }
    }
  }

  updateState(updated): void {
    this.setState(
      () => {
        return {
          ...updated
        };
      },
      () => {
        this.fetchGifts();
      }
    );
  }

  onBottomHit(): void {
    if (this.state.isInitialLoad) {
      this.updateState({ page: 1 });
    } else {
      if (!this.state.isFetchingNextPage && this.state.hasMoreData) {
        this.updateState({ page: this.state.page + 1 });
      }
    }
  }

  onPageUpdated(page): void {
    this.updateState({ page });
  }

  onSortUpdated(sort): void {
    this.updateState({
      page: 1,
      sort,
      gifts: [],
      nextGifts: [],
      hasMoreData: true
    });
  }

  onStoreChanged(id: string | number, name: string): void {
    this.updateState({
      storeId: id,
      storeName: name,
      page: 1,
      gifts: [],
      hasMoreData: true
    });
  }

  onCurrentGift(currentGift: GiftInterface): void {
    this.setState(() => {
      return {
        currentGift
      };
    });
  }

  onProductModalIsOpen(productModalIsOpen: boolean): void {
    this.setState(() => {
      return {
        productModalIsOpen
      };
    });
  }

  onModalClose(): void {
    this.setState(() => {
      return {
        productModalIsOpen: false
      };
    });
    window.history.back();
  }

  handleOnProductShareClicked(): void {
    this.setState({ openProductShareModal: true });
  }

  onGiftShareModalClose(): void {
    this.setState({ openProductShareModal: false });
  }

  componentDidMount(): void {
    this.updateState({ storeId: this.props.match.params.id, storeName: this.props.match.params.storename });
  }

  render() {
    const {
      onBottomHit,
      onPageUpdated,
      onSortUpdated,
      onCurrentGift,
      onProductModalIsOpen,
      onModalClose,
      onStoreChanged,
      state,
      props
    } = this;

    const { isFetching, hasMoreData, page, gifts, sort, productModalIsOpen, currentGift } = state;
    const { loadedStores, setPromptLogin, history } = props;
    return (
      <AppLayout
        theme={theme}
        headerVariant="header1"
        backgroundColor={backgroundTheme.grayFill}
        footerVariant={FOOTER_VARIANT2}
        footerBottomFixed={!hasMoreData || isFetching}
      >
        <InfiniteScroll
          onBottomHit={onBottomHit}
          isLoading={isFetching}
          hasMoreData={hasMoreData}
          loadOnMount={false}
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem"
          }}
          additionalInnerHeight={1200}
        >
          <Section>
            <StoreHeader
              storeName={props.match.params.storename}
              loadedStores={loadedStores}
              onStoreChanged={onStoreChanged}
            />
          </Section>
          <Section
            style={{
              marginTop: 35
            }}
          >
            <GiftResults
              isLoading={isFetching}
              isPaginated={false}
              hasMoreData={hasMoreData}
              page={page}
              totalPages={5}
              setPage={onPageUpdated}
              gifts={gifts}
              sortBy={sort}
              setSortBy={onSortUpdated}
              setCurrentGift={onCurrentGift}
              setProductModalIsOpen={onProductModalIsOpen}
              storeId={props.match.params.id}
              storeName={props.match.params.storename}
              setPromptLogin={setPromptLogin}
            />
          </Section>
          <GiftModal
            open={productModalIsOpen}
            onClose={onModalClose}
            gift={currentGift}
            setPromptLogin={setPromptLogin}
            onShareProductClicked={this.handleOnProductShareClicked}
          />

          <GiftShareModal
            open={this.state.openProductShareModal}
            onClose={this.onGiftShareModalClose}
            gift={currentGift}
          />
        </InfiniteScroll>
      </AppLayout>
    );
  }
}

export default Store;
