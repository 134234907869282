import axios, { AxiosResponse } from "axios";
import { updateProfile } from "../sdk";

interface UpdateUserPayloadInterface {
  nickname: string;
  email: string;
  password?: string;
  firstname: string;
  lastname: string;
  address?: string;
  country?: string;
  city?: string;
  state?: string;
  zip?: string;
  gender: string;
}

interface SetPasswordPayloadInterface {
  password: string;
  confirmPassword: string;
}
interface UpdatePasswordPayloadInterface {
  password: string;
  confirmPassword: string;
  currentPassword: string;
}

interface TransferUserCreditsPayloadInterface {
  sourceChannelPartnerId: string, 
  sourceUserId: string
}

export function formatUserPaymentData(paymentProfileData) {
  const paymentInfos = [];
  const storeCredits = [];

  for (const key in paymentProfileData) {
    if (paymentProfileData[key].account_type === "Store Credit") {
      storeCredits.push(paymentProfileData[key]);
    } else {
      paymentInfos.push(paymentProfileData[key]);
    }
  }
  return { paymentInfos, storeCredits };
}

export async function getCurrentUser(): Promise<AxiosResponse<any>> {
  return axios.get("/api/user");
}

export const validateUserNickname = async (nickname: string): Promise<{ isAvailable: boolean; message: string }> => {
  try {
    const response = await axios.post("/api/user/validate-nickname", { nickname });
    return response.data;
  } catch (error) {
    console.log("Error validating user vanity", error);
  }
};

export const  updateProfileAction = async (payload: UpdateUserPayloadInterface) => {
  return await updateProfile(payload);
};

export const setPassword = (payload: SetPasswordPayloadInterface): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/set-password", payload);
};

export const updatePassword = (payload: UpdatePasswordPayloadInterface): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/update-password", payload);
};

export const getUserAccounts = (): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/get-user-accounts");
}

export const transferUserCredits = (payload: TransferUserCreditsPayloadInterface): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/transfer-user-credits", payload);
}

interface IUnlinkSocialLogin {
  id?: string | number;
  social?: string;
}

export const unLinkSocialLogin = (payload: IUnlinkSocialLogin): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/unlink-social-login", { ...payload });
};

export const validateUserEmail = (email: string): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/validate-email", { email });
};

export const uploadProfilePicture = (photo: string): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/update-profile-picture", { photo });
};

export const updateUserAbout = (about: string): Promise<AxiosResponse<any>> => {
  return axios.post("/api/user/update-about", { about });
};
