import React from "react";
import { Box, FormControl, Link, makeStyles, Typography } from "@material-ui/core/";
import SectionTitle from "../../common/sectionTitle/SectionTitle";
import TextInput from "../../input/TextInput";
import Caption from "../../common/caption/Caption";
import Form, { FormInput } from "../../input/Form";
import PrimaryButton from "../../buttons/PrimaryButton";
import Toast from "light-toast";
import RollbarTracker from "../../../utils/RollbarTracker";
import { signInOrSignUpAction } from "../../../actions/authAction";

const CreateAccountWithPasswordStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "24px"
  },
  createAccountButton: {
    width: "100%",
    height: "50px",
    borderRadius: "25px",
    marginTop: "24px"
  },
  skipTypography: {
    marginTop: "40px",
    textAlign: "center"
  },
  skipLink: {
    color: theme.palette.grey["A400"]
  }
}));

export const initialFormInputs = {
  password: FormInput()
};

export function CreateAccountWithPassword(props) {
  const styles = CreateAccountWithPasswordStyles();

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (let inputName in inputs) {
      const formInput = inputs[inputName];

      if ("password" === inputName) {
        formInput.error =
          formInput.value.length !== 0
            ? formInput.value.length <= 3
              ? "Password too short."
              : ""
            : "Password is required.";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const createAccount = () => {
    if (!validateForm()) {
      setFormInputs({ ...formInputs });

      return;
    }

    Toast.loading("Please wait...");

    let payload: any = {
      email: props.email.toString().toLowerCase(),
      password: formInputs.password.value,
      returnTo: "/store/storehome"
    };

    signInOrSignUpAction(payload)
      .then((response) => {
        console.log("Create account - response:", response);

        if (response.data.redirectTo) {
          // Redirects when user exists
          console.log(
            "Password authentication, redirecting to: " + "https://" + window.location.host + response.data.redirectTo
          );
          Toast.info("Success!", 500, () => {
            window.location.href = "https://" + window.location.host + response.data.redirectTo;

            localStorage.setItem("loggedInBefore", "true");
          });
        } else {
          const errorMsg = "Error on creating an account. Please try again";
          console.log(errorMsg);
          Toast.info(errorMsg, 1000);
        }
      })
      .catch((error) => {
        const errorMsg = "Error on email authentication.";

        RollbarTracker.logError(errorMsg, error);
        console.log(errorMsg, error);

        if (error.response.status === 1000) {
          props.history.push("/error");
        }
      });
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  return (
    <Box>
      {props.hasSession ? (
        <div>
          <SectionTitle>Make it easier next time</SectionTitle>
          <Caption style={{ marginTop: "8px" }}>Just add a password below to create your account.</Caption>
          <FormControl fullWidth className={styles.formControl}>
            <TextInput
              id="password"
              name="password"
              label="New password"
              type="password"
              fullWidth={true}
              value={formInputs.password.value}
              error={formInputs.password.error}
              helperText={formInputs.password.error}
              onChange={handleInputChange}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </FormControl>
          <PrimaryButton className={styles.createAccountButton} handleClick={() => createAccount()}>
            Create your account
          </PrimaryButton>
          <Typography className={styles.skipTypography}>
            <Link href="/store/storehome" underline="always" className={styles.skipLink}>
              Skip and return to home
            </Link>
          </Typography>
        </div>
      ) : (
        <Typography className={styles.skipTypography}>
          <Link href="/store/storehome" underline="always" className={styles.skipLink}>
            Return to home
          </Link>
        </Typography>
      )}
    </Box>
  );
}
