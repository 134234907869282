import React from "react";
import { Box, Typography } from "@material-ui/core";
import GifterSpecialNote from "./GifterSpecialNote";
import ReceivedGiftImage from "./ReceivedGiftImage";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import { RedeemGiftNameStyle } from "./RedeemGiftFlowStyles";

export default function DeclinedGiftAndCreditDialogContent(props: any) {
  const redeemGiftNameStyle = RedeemGiftNameStyle();

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" style={{ paddingBottom: "16px" }}>
      <Typography variant="body1">Order number: {props.order.order_id}</Typography>

      <ReceivedGiftImage
        alt="Gift Image"
        src={props.order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />

      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "8px" }}>
        {props.order.gift.name}
      </Typography>

      <GifterSpecialNote
        specialNote={props.order.message}
        gifterName={props.buyerNickname}
        style={{ marginTop: "16px" }}
      />

      <RedeemGiftDialogButtons
        positiveButtonText="Ok, go to wishlist"
        onPositiveButtonClick={props.onOpenWishlistClicked}
        onNegativeButtonClick={props.onClose}
        negativeButtonText="Close"
      />
    </Box>
  );
}
