import React from "react";
import { Typography, CircularProgress, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { textColor } from "../../style/index";
import clsx from "clsx";

const ProfileStyles = makeStyles(() => ({
  sectionHeader: {
    color: textColor.DARK1
  }
}));

interface ISectionHeaderProps {
  text: string;
  className?: string;
  size?: number;
}
const SectionHeaderTitle: React.FC<ISectionHeaderProps> = ({ text, className, size }: ISectionHeaderProps) => {
  const profileStyles = ProfileStyles();
  return (
    <Typography variant="h4" className={clsx(profileStyles.sectionHeader, className)}>
      {text}
    </Typography>
  );
};

interface ILoadingStateProps {
  size?: number;
}

const LoadingState: React.FC<ILoadingStateProps> = ({size}) => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress size={size} />
    </Box>
  );
};

export { SectionHeaderTitle, LoadingState };
