import axios from "axios";
import { History } from "history";
import RollbarTracker from "../../utils/RollbarTracker";

export async function checkoutWithGift(history: History, gift: any, receiverNickname: string = "") {
  try {
    let recipientUserId = null;
    const selectedRecipient = JSON.parse(localStorage.getItem("selectedRecipient"));

    if (selectedRecipient && selectedRecipient.userId) {
      recipientUserId = selectedRecipient.userId;
      console.log("buying for selected recipient: ", selectedRecipient);
    }

    const response = await axios.get("/api/user/id");

    if (response && response.data && response.data.encryptedUserId) {
      history.push(
        "/checkout/" + (recipientUserId ? recipientUserId : response.data.encryptedUserId) + "/" + gift.gift_id,
        gift
      );
    } else {
      if (receiverNickname) {
        gift.receiverNickname = receiverNickname;
      }

      history.push("/checkout/" + (recipientUserId || "myself") + "/" + gift.gift_id + "/guest", gift);
    }
  } catch (error) {
    const errorMsg = "Error on fetching user and/or recipient on checkout.";
    console.log(errorMsg, error);
    RollbarTracker.logError(errorMsg, error);

    if (error.response.status === 500) {
      history.push("/error");
    }
  }
}
