import React from "react";
import { Typography, Box } from "@material-ui/core";
import PrimaryButton from "../buttons/PrimaryButton";

export default function PreferToChat(props: any) {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
      style={{
        position: "relative",
        height: "160px",
        marginTop: "90px"
      }}
    >
      <Box
        style={{
          width: "100%",
          position: "absolute",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: "50px",
          paddingBottom: "55px"
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: "#272727",
            fontSize: "25px",
            fontWeight: 600,
            lineHeight: "32px",
            marginBottom: 12
          }}
        >
          Prefer to chat?
        </Typography>
        <PrimaryButton
          id="talk-to-us-now"
          handleClick={props.handleClick}
          style={{
            maxWidth: 190
          }}
        >
          Talk to Us Now
        </PrimaryButton>
      </Box>
    </Box>
  );
}
