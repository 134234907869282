import React, { useEffect, useState } from "react";
import { Box, TablePagination, Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DESKTOP_CONTENT_WIDTH } from "../../common/Constants";
import ProfileContentContainer from "../ProfileContentContainer";
import { getOrders, orderParty } from "../../../actions/orderAction";
import PerMyGift from "./PerMyGift";
import { textColor } from "../../../style";
import EmptyView from "../../common/emptyView/EmptyView";
import PostSignupDialog from "../../postSignup/PostSignupDialog";
import useMediaBreakpoints from "../../../hooks/useMediaBreakpoints";

const useOrderHistoryStyle = makeStyles((theme: Theme) => ({
  contentStyle: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(24),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      width: DESKTOP_CONTENT_WIDTH
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "900px"
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "600px"
    }
  },
  flexContainer: {
    display: "flex",
    "flex-wrap": "wrap"
  },
  hover: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  empty: {
    textAlign: "center",
    minWidth: theme.typography.pxToRem(500),
    paddingTop: theme.typography.pxToRem(50),
    paddingBottom: theme.typography.pxToRem(50)
  },
  removeButtonStyle: {
    ...theme.typography.body2,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    padding: 0,
    color: textColor.NORMAL,
    fontWeight: "bold"
  },
  removeButtonStyleDeclineGift: {
    ...theme.typography.body2,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    padding: 0,
    color: textColor.DARK1,
    fontWeight: "normal",
    margin: "auto",
    textDecoration: "underline"
  }
}));

export default function MyGifts(props) {
  const { isDesktop } = useMediaBreakpoints();
  const styles = useOrderHistoryStyle();
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [count, setCount] = useState<number>(-1);
  const [countItems, setCountItems] = useState<number>(0);
  const [visitedPage, setVisitedPage] = useState<number>(0);
  const itemsPerPage = 8;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setLoading(true);
    setPage(newPage);
  };

  useEffect(() => {
    getOrders(page + 1, itemsPerPage, orderParty.Receiver)
      .then((response) => {
        const mainData = response.data;
        const meta = response.data.meta;
        const totalCount = meta.total_count;
        setOrders(mainData.order);

        if (count < 0 && (page > visitedPage || countItems <= 0)) {
          setVisitedPage(page);
          setCountItems(totalCount);
          setHasNext(true);
          setCount(totalCount);
        }
      })
      .catch(() => {
        setOrders([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <>
      <ProfileContentContainer page="gifts_received" isLoading={loading}>
        {isDesktop ? (
          orders.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Gift Details</th>
                  <th style={{ textAlign: "left" }}>From</th>
                  <th style={{ textAlign: "left" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <PerMyGift key={order.order_id} styles={styles} order={order} isDesktop={isDesktop} />
                ))}
              </tbody>
            </table>
          ) : (
            <EmptyView type="myGifts" />
          )
        ) : (
          <>
            {orders.length > 0 ? (
              orders.map((order) => (
                <PerMyGift key={order.order_id} styles={styles} order={order} isDesktop={isDesktop} />
              ))
            ) : (
              <EmptyView type="myGifts" />
            )}
          </>
        )}
        {hasNext && (
          <Box>
            <TablePagination
              rowsPerPageOptions={[]}
              count={count}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </ProfileContentContainer>
      <PostSignupDialog />
    </>
  );
}
