import React, { useState, useEffect } from "react";
import ProfileContentContainer from "../ProfileContentContainer";
import { useAppSelector } from "../../../hooks";
import { getCurrentUser, getUserAccounts } from "../../../actions/userAction";
import { useHistory } from "react-router-dom";
import useNav from "../../../hooks/useNav";
import { STOREHOME_PATH } from "../../../constants/paths";
import { Box, Typography } from "@material-ui/core";
import { UserAccountSync } from "models/UserAccountSync";
import AccountSyncRow from "./AccountSyncRow";

const AccountSync: React.FC = () => {
  const history = useHistory();
  const { goTo } = useNav();
  const { localDbData } = useAppSelector((state) => state.user.data);
  const [state, setState] = useState({
    isSaving: false,
    currentUserLoaded: false
  });
  const [userAccounts, setUserAccounts] = useState<UserAccountSync[]>(undefined);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const fetchUserAccounts = async () => {
    try {
      const userAccounts = await getUserAccounts();
      setUserAccounts(userAccounts.data.userAccounts.filter((account) => account.sourceUserId !== localDbData.userId));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401
        goTo(STOREHOME_PATH);
        return;
      } else {
        if (error.response && error.response.status === 500) {
          history.push("/error");
          return;
        }
      }
    }
  };

  const fetchUser = async () => {
    try {
      await getCurrentUser();
      setState({
        ...state,
        currentUserLoaded: true
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401
        goTo(STOREHOME_PATH);
        return;
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (state.currentUserLoaded && localDbData && localDbData.userId) {
      fetchUserAccounts();
    }
  }, [state.currentUserLoaded, localDbData]);

  return (
    <ProfileContentContainer page="account_sync" isLoading={!state.currentUserLoaded || !localDbData || !userAccounts}>
      {userAccounts && userAccounts.length === 0 ? (
        <Typography variant="h4">No accounts to sync</Typography>
      ) : (
        <>
          <Typography variant="h4">Accounts to Sync</Typography>
          <Box style={{ display: "flex", flexDirection: "column", marginTop: 16 }}>
            {userAccounts &&
              userAccounts.map((account) => {
                return (
                  <AccountSyncRow
                    accountSync={account}
                    isSynced={false}
                    styles={{ marginBottom: 16 }}
                    setIsAccountSyncing={setIsSyncing}
                    disableOverride={isSyncing}
                  />
                );
              })}
          </Box>
        </>
      )}
    </ProfileContentContainer>
  );
};

export default AccountSync;
