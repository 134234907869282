import React, { useState, useEffect } from "react";
import { makeStyles, Box, Divider } from "@material-ui/core";
import ProfileContentContainer, { ProfileContentContainerStyles } from "../ProfileContentContainer";
import GiftDetailsOrderDetails from "./GiftDetailsOrderDetails";
import GiftDetailsFromDetails from "./GiftDetailsFromDetails";
import GiftDetailsProductInformation from "./GiftDetailsProductInformation";
import GiftDetailsTotal from "./GiftDetailsTotal";
import { useParams, useHistory } from "react-router-dom";
import { getOrderById } from "../../../sdk";
import Toast from "light-toast";
import { GiftDetailsType, GiftOptions } from "./GiftDetailsTypes";
import { useLocation } from "react-router-dom";

export const GiftDetailsStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  title: {
    marginBottom: "32px",
    fontWeight: "bold",
    fontSize: "22px"
  },
  subTitle: {
    marginBottom: "24px",
    fontWeight: "bold"
  },
  name: {
    marginBottom: "8px",
    fontWeight: "bold"
  },
  address: {
    marginBottom: "32px"
  },
  column: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "40%"
    }
  },
  subColumn: {
    marginTop: "48px"
  },
  nickname: {
    marginTop: "16px"
  },
  label: {
    fontWeight: "bold"
  },
  img: {
    borderRadius: "12px"
  }
}));

const GiftDetails = () => {
  const history = useHistory();
  const { orderId } = useParams();
  const styles = GiftDetailsStyles();
  const profileContentContainerStyles = ProfileContentContainerStyles();
  const [orderData, setOrderData] = useState<GiftDetailsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const prepareOrderData = (order): void => {
    const orderData = order.order[0];
    const buyer = order.buyer;
    const buyer_nickname = order.buyer_nickname;

    // prepare gifts options
    const options: GiftOptions[] = [];
    for (let i = 1; i <= 3; i++) {
      const name = orderData.gift[`option_${i}_name`];
      const values = JSON.parse(orderData.gift[`option_${i}_values`]);
      const values_selected = orderData.gift[`option_${i}_values_selected`];
      options.push({
        name,
        values,
        values_selected
      });
    }

    setOrderData({
      buyer_id: orderData.buyer_id,
      order_id: orderData.order_id,
      date_ordered: orderData.date_ordered,
      date_claimed: orderData.date_claimed,
      receiver_firstname: orderData.receiver_firstname,
      receiver_lastname: orderData.receiver_lastname,
      receiver_address: orderData.receiver_address,
      receiver_city: orderData.receiver_city,
      receiver_state: orderData.receiver_state,
      receiver_zip: orderData.receiver_zip,
      receiver_country: orderData.receiver_country,
      receiver_phone: orderData.receiver_phone,
      receiver_nickname: orderData.receiver_nickname,
      buyer_address: buyer.address,
      buyer_city: buyer.city,
      buyer_state: buyer.state,
      buyer_zip: buyer.zip,
      buyer_country: buyer.country,
      buyer_phone: buyer.phone,
      message: orderData.message,
      claim_status: orderData.claim_status,
      buyer_nickname,
      gift: {
        image_url: orderData.gift.image_url,
        image_url_high_res: orderData.gift.image_url_high_res,
        image_url_max_res: orderData.gift.image_url_max_res,
        name: orderData.gift.name,
        brand: orderData.gift.brand,
        options
      },
      pricing: {
        price_paid_dollars: orderData.pricing.price_paid_dollars,
        shipping_price_paid_dollars: orderData.pricing.shipping_price_paid_dollars,
        tax_price_paid_dollars: orderData.pricing.tax_price_paid_dollars,
        total_price_paid_dollars: orderData.pricing.total_price_paid_dollars
      }
    });
  };

  const getOrder = async () => {
    try {
      const response = await getOrderById(orderId);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        history.push("/store/storehome");
        return;
      }

      setLoading(false);
      Toast.fail("Error fetching order.", 404);
    }
  };

  useEffect(() => {
    setLoading(true);

    getOrder().then((orderResponse) => {
      if (orderResponse.order && orderResponse.order[0]) {
        prepareOrderData(orderResponse);
        setLoading(false);
      }
    });
  }, [history, orderId]);

  const search = useLocation().search;
  const isOrder = new URLSearchParams(search).get("is_order");

  return (
    <ProfileContentContainer page="gift_details" isLoading={loading && !orderData}>
      {orderData && !loading && (
        <>
          <Box display="flex" className={styles.root}>
            <GiftDetailsOrderDetails orderData={orderData} isOrder={!!isOrder} />
            <GiftDetailsFromDetails orderData={orderData} isOrder={!!isOrder} />
          </Box>
          <Divider className={profileContentContainerStyles.divider} />
          <Box>
            <GiftDetailsProductInformation
              orderData={orderData}
              totalPrice={orderData.pricing.total_price_paid_dollars}
            />
          </Box>
          <Divider className={profileContentContainerStyles.divider} />
          <Box>
            <GiftDetailsTotal pricing={orderData.pricing} />
          </Box>
        </>
      )}
    </ProfileContentContainer>
  );
};

export default GiftDetails;
