import React, { useContext, useEffect, useState } from "react";
import AppDialog from "../dialog/AppDialog";
import AppDialogMobile from "../dialog/AppDialogMobile";
import GiftContainerDesktop from "./GiftContainerDesktop";
import GiftContainerMobile from "./GiftContainerMobile";
import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { checkoutWithGift } from "../../gift/GiftUtils";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { decrementUserWishlistCount, incrementUserWishlistCount } from "../../../reducers/user/UserSlice";
import { addWishlist, removeWishlist } from "../../../actions/wishlistAction";
import { GiftInterface } from "../../../models/gift";
import { SetPromptLoginInterface } from "../../../interface/AppInterface";
import useNav from "../../../hooks/useNav";
import { ERROR_PATH, PROFILE_PERSONAL_PATH } from "../../../constants/paths";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { textColor } from "../../../style/index";
import GiftShareIcon from "../../customIcons/GiftShareIcon";
import GiftModalMobileActionButtons from "./GiftModalMobileActionButtons";

const DialogTitleComponentStyles = makeStyles((theme) => ({
  headerContainer: {
    height: 55,
    borderBottom: `1px solid ${textColor.LIGHT_GRAY1}`,
    paddingRight: 10,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center"
  },
  icon: {
    color: textColor.LIGHT_GRAY2,
    padding: 5,
    margin: "0 5px"
  }
}));

const GiftShareIconWithStyles = withStyles({
  root: {
    "&.MuiSvgIcon-root": {
      width: ".8em",
      height: ".8em"
    }
  }
})(GiftShareIcon);
interface GiftModalProps {
  open: boolean;
  onClose: () => void;
  gift: GiftInterface;
  setPromptLogin: React.Dispatch<React.SetStateAction<SetPromptLoginInterface>>;
  onShareProductClicked?: () => void;
}
const GiftModal: React.FC<GiftModalProps> = ({
  open,
  onClose,
  gift,
  setPromptLogin,
  onShareProductClicked
}: GiftModalProps) => {
  const { goTo } = useNav();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [encryptedUserId, setEncryptedUserId] = useState(undefined);
  const { email, nickname, gender } = useAppSelector((state) => state.user.data);
  const isProfileComplete = encryptedUserId
    ? email && email.length !== 0 && nickname && nickname.length !== 0 && gender && gender.length !== 0
    : false;

  const history = useHistory();

  const fetchEncryptedUserId = () => {
    axios
      .get("/api/user/id")
      .then((response) => {
        setEncryptedUserId(response.data.encryptedUserId);
      })
      .catch((error) => {
        console.log("error: " + error);
      });
  };

  const isUserCapable = () => {
    if (encryptedUserId === undefined) {
      // todo - returnTo should go back to this page
      setPromptLogin({
        promptLogin: true,
        returnTo: undefined,
        registration: false
      });
      return false;
    } else if (!isProfileComplete) {
      goTo(PROFILE_PERSONAL_PATH);
      return false;
    }

    return true;
  };

  const addGiftToWishlist = async (e) => {
    e.stopPropagation();

    if (!isUserCapable()) {
      return;
    }

    if (gift.is_in_wishlist) return;

    updateGiftWishlistState(true);
    dispatch(incrementUserWishlistCount());

    try {
      await addWishlist(gift);
    } catch (error) {
      dispatch(decrementUserWishlistCount());
      updateGiftWishlistState(false);
      if (error.response.status === 500) {
        goTo(ERROR_PATH);
      }
    }
  };

  const removeGiftFromWishlist = async (e) => {
    e.stopPropagation();
    if (!gift.is_in_wishlist) return;

    updateGiftWishlistState(false);
    dispatch(decrementUserWishlistCount());
    try {
      await removeWishlist(gift);
    } catch (error) {
      dispatch(incrementUserWishlistCount());
      updateGiftWishlistState(true);
      if (error.response.status === 500) {
        goTo(ERROR_PATH);
      }
    }
  };

  const updateGiftWishlistState = (addedToWishlist) => {
    gift.is_in_wishlist = addedToWishlist;
  };

  const handleBuyGift = async (e) => {
    e.stopPropagation();

    if (encryptedUserId && !isProfileComplete) {
      goTo(PROFILE_PERSONAL_PATH);
      return;
    }

    await checkoutWithGift(history, gift);
  };

  const shareProductClicked = () => {
    if (!isUserCapable()) {
      return;
    }

    onShareProductClicked();
  };

  const DialogTitleComponent = () => {
    const styles = DialogTitleComponentStyles();

    return (
      <Box className={styles.headerContainer}>
        <IconButton aria-label="close" className={styles.icon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {encryptedUserId && (
          <>
            <Box
              style={{
                height: 20,
                width: 1,
                background: "#E2E2E2"
              }}
            ></Box>
            <IconButton
              aria-label="close"
              className={styles.icon}
              onClick={shareProductClicked}
              style={{
                paddingTop: 4
              }}
            >
              <GiftShareIconWithStyles />
            </IconButton>
          </>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (encryptedUserId) {
      setEncryptedUserId(encryptedUserId);
    } else {
      fetchEncryptedUserId();
    }
  });

  if (isMobile) {
    return (
      <>
        <AppDialogMobile
          open={open}
          onClose={onClose}
          fullWidth={false}
          title="Example"
          noHeaderLogo={true}
          isNotTransparent={true}
          titleComponent={DialogTitleComponent}
          footer={
            <GiftModalMobileActionButtons
              gift={gift}
              addGiftToWishlist={addGiftToWishlist}
              removeGiftFromWishlist={removeGiftFromWishlist}
              handleBuyGift={handleBuyGift}
            />
          }
        >
          <GiftContainerMobile gift={gift} />
        </AppDialogMobile>
      </>
    );
  }

  return (
    <AppDialog open={open} onClose={onClose} fullWidth={false} titleComponent={DialogTitleComponent}>
      <GiftContainerDesktop
        gift={gift}
        addGiftToWishlist={addGiftToWishlist}
        removeGiftFromWishlist={removeGiftFromWishlist}
        handleBuyGift={handleBuyGift}
      />
    </AppDialog>
  );
};

export default GiftModal;
