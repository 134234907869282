import React from "react";
import TextInput from "../input/TextInput";
import PrimaryButton from "../buttons/PrimaryButton";
import { makeStyles } from "@material-ui/core/styles";
import { isProdENVOnly } from "../../utils/appEnv";

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 16,
    paddingBottom: "20px"
  },
  button: {
    width: "32%"
  },
  textField: {
    paddingLeft: "14px"
  }
}));

export default function ContactUsForm(props: any) {
  const classes = useStyles();

  return (
    <div>
      <form
        id={"formContactUs"}
        onSubmit={(e) => props.handleOnSubmit(e)}
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        method="POST"
      >
        <TextInput
          id="orgid"
          name="orgid"
          type="hidden"
          value={"00D4x000006sRrL"}
          style={{
            display: "none"
          }}
        />
        <TextInput
          id="retURL"
          name="retURL"
          type="hidden"
          value={
            isProdENVOnly() ? `https://gift.me/contact-us` : `https://${process.env.REACT_APP_ENV}.gift.me/contact-us`
          }
          style={{
            display: "none"
          }}
        />
        <TextInput
          id="name"
          name="name"
          label="Your Name"
          marginTop="6px"
          width="100%"
          className={classes.input}
          value={props.name.value}
          error={props.name.error}
          helperText={props.name.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          maxLength={80}
        />
        <TextInput
          id="email"
          name="email"
          label="Email"
          width="100%"
          className={classes.input}
          value={props.email.value}
          error={props.email.error}
          helperText={props.email.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          maxLength={80}
        />
        <TextInput
          id="subject"
          name="subject"
          label="Subject"
          width="100%"
          className={classes.input}
          value={props.subject.value}
          error={props.subject.error}
          helperText={props.subject.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
          maxLength={80}
        />
        <TextInput
          id="description"
          name="description"
          label="Message"
          multiline={true}
          rows={"3"}
          width="100%"
          className={classes.input}
          value={props.description.value}
          error={props.description.error}
          helperText={props.description.error}
          onChange={props.handleInputChange}
          onFocus={props.handleOnFocus}
          onBlur={props.handleOnBlur}
        />
        <PrimaryButton type={"submit"} className={classes.button}>
          Submit
        </PrimaryButton>
      </form>
    </div>
  );
}
