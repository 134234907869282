import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
  Link
} from "@material-ui/core";
import SupportedCards from "./supported_cards.png";
import Form from "../input/Form";
import { CARD_CVV_LABEL, CARD_CVV_SIZE } from "../input/card/CardUtils";
import { initialFormInputs } from "./CheckoutFormInputs";
import PaymentInformationForm from "./PaymentInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import BillingInformationEmailForm from "./BillingInformationEmailForm";
import PrimaryButton from "../buttons/PrimaryButton";
import { CheckoutStyles } from "./CheckoutStyles";
import axios from "axios";
import { roundToDecimalPlaces } from "../../utils/stringUtils";
import CheckoutGiftDetail from "./CheckoutGiftDetail";
import { GiftSummary } from "./GiftSummary";
import ImageLock from "./lock.svg";
import SectionTitle from "../common/sectionTitle/SectionTitle";
import CheckoutSpecialMessage from "./CheckoutSpecialMessage";
import PaymentMethodDialog from "./paymentmethod/PaymentMethodDialog";
import Caption from "../common/caption/Caption";
import SavedCheckoutPaymentInfo from "./SavedCheckoutPaymentInfo";
import ChangeBillingInfoDialog from "./billingaddress/ChangeBillingInfoDialog";
import AllRightReservedComponent from "../common/AllRightReservedComponent";
import Toast from "light-toast";
import { CURRENCY } from "../common/Constants";
import LoginDialog from "../login/LoginDialog";
import {
  getAddressByZipCode,
} from "../../actions/zipCodeAction";
import ReactPixel from "react-snapchat-pixel";
import { vysionPaymentEvent, EVENT_PAYMENT_ATTEMPT } from "../../actions/vysionAction";

interface OrderGiftPayloadObject {
  receiver_user_id: string;
  gift_id: string;
  total_price_converted: string;
  account_number: string;
  billing_name: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  billing_zip: string;
  billing_country: string;
  billing_email: string;
  special_message: string | undefined;
  sender_nickname: string;
  sender_firstname: string;
  sender_lastname: string;
  sender_country: string;
  payment_profile_id?: string;
  exp_month?: string;
  exp_year?: string;
  csv?: string;
  credit_amount_converted: number | undefined;
  credit_profile_id: number | undefined;
}

export default function CheckoutV2(props) {
  const styles = CheckoutStyles();

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  const [pageData, setState] = useState({
    user: undefined,
    receiverUser: undefined,
    gift: props.location.state,
    guestCheckoutReceiverNickname:
      typeof props.location.state !== "undefined" && typeof props.location.state.receiverNickname !== "undefined"
        ? props.location.state.receiverNickname
        : null,
    checkoutData: undefined,
    checkoutInProgress: false,
    error: null,
    openChoosePaymentMethodDialog: false,
    openChooseBillingAddressDialog: false,
    paymentInfo: null,
    paymentInfos: null,
    encryptedUserId: null
  });

  const [isLongLoading, setIsLongLoading] = useState(false);
  const [isToSubmit, setIsToSubmit] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [includeSpecialMessage, setIncludeSpecialMessage] = useState(false);
  const [promptLogin, setPromptLogin] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isVysionPaymentEventTriggered, setIsVysionPaymentEventTriggered] = useState<boolean>(false);
  const [addresses, setAddresses] = useState([]);
  const [rawAddresses, setRawAddresses] = useState([]);

  const prefillFormInputValue = (formInput, value) => {
    if (value && value !== "") {
      formInput.value = value;
    }
  };

  const fetchReceiverUser = () => {
    let userId = props.match.params.receiverId;
    if (props.match.params.receiverId === "myself" && !props.match.params.hasSession) {
      userId = pageData.user.encryptedUserId;
    }

    axios
      .get(`/api/receiver/nickname?user_id=${userId}`)
      .then((response) => {
        setState({
          ...pageData,
          receiverUser: response.data
        });
      })
      .catch((error) => {
        setState({
          ...pageData,
          error: error
        });

        if (error.response.status === 401) {
          props.history.push("/store/storehome");
        }

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const fetchUser = () => {
    axios
      .get("/api/user/")
      .then((response) => {
        const firstname = response.data.firstname;
        const lastname = response.data.lastname;

        if (firstname !== "" && lastname !== "") {
          prefillFormInputValue(formInputs.billing_name, `${firstname} ${lastname}`);
        }

        prefillFormInputValue(formInputs.sender_name, response.data.nickname);
        prefillFormInputValue(formInputs.billing_email, response.data.email);

        const paymentInfos = [];
        let paymentInfo = undefined;

        for (const key in response.data.payment_profile_data) {
          // Sets default payment info
          if (!paymentInfo && response.data.payment_profile_data[key].account_type !== "Store Credit") {
            paymentInfo = response.data.payment_profile_data[key];
          }

          // Sets payment info options
          if (response.data.payment_profile_data[key].account_type !== "Store Credit") {
            paymentInfos.push(response.data.payment_profile_data[key]);
          }
        }

        populateBillingInfo(paymentInfo);

        setFormInputs({
          ...formInputs
        });

        setState({
          ...pageData,
          user: response.data,
          paymentInfo: paymentInfo,
          paymentInfos: paymentInfos,
          encryptedUserId:
            props.match.params.receiverId === "myself" && !props.match.params.hasSession
              ? response.data.encryptedUserId
              : null
        });
      })
      .catch((error) => {
        setState({
          ...pageData,
          error: error
        });

        if (error.response.status === 401) {
          props.history.push("/store/storehome");
        }

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const fetchGift = () => {
    let userId = pageData.encryptedUserId || props.match.params.receiverId;

    axios
      .get("/api/gifts/getgift?giftId=" + props.match.params.giftId + `&userId=${userId}`)
      .then((response) => {
        const gift = Object.values(response.data.gifts)[0];
        setState({
          ...pageData,
          gift: gift
        });
      })
      .catch((error) => {
        setState({
          ...pageData,
          error: error
        });

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const getCheckoutData = () => {
    axios
      .post("/api/getCheckoutData", {
        receiver_user_id: pageData.encryptedUserId || props.match.params.receiverId,
        gift_id: props.match.params.giftId
      })
      .then((response) => {
        const checkoutData = response.data;
        setState({
          ...pageData,
          checkoutData: checkoutData
        });
      })
      .catch((error) => {
        setState({
          ...pageData,
          error: error
        });

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const doesUserHaveEnoughCredit = () => {
    return (
      pageData.checkoutData &&
      pageData.checkoutData.credit_amount_converted &&
      pageData.checkoutData.credit_amount_converted > 0 &&
      pageData.checkoutData.total_price_converted <= 0
    );
  };

  const validateCard = () => {
    if (pageData.paymentInfo && pageData.paymentInfo.payment_profile_id) {
      return "";
    }

    let errorString = "";

    if (
      formInputs.card.cardNumber === "" ||
      formInputs.exp_month.value === "" ||
      formInputs.exp_year.value === "" ||
      formInputs.csv.value === ""
    ) {
      errorString = "Please enter card details.";
    } else if (formInputs.exp_year.value.length !== 2) {
      errorString = "Please enter the last 2 digits of expiry year!";
    } else if (formInputs.exp_month.value.length !== 2) {
      errorString = "Please enter a 2 digit expiry month!";
    } else if (formInputs.csv.value.length !== formInputs.csv.maxLength) {
      errorString = "Please enter a " + formInputs.csv.maxLength + "digit " + formInputs.csv.label + "!";
    }

    return errorString;
  };

  const populateBillingInfo = (paymentInfo) => {
    if (!paymentInfo) return;

    prefillFormInputValue(formInputs.billing_name, paymentInfo.billing_name);

    prefillFormInputValue(formInputs.billing_address, paymentInfo.billing_address);

    prefillFormInputValue(formInputs.billing_country, paymentInfo.billing_country);

    prefillFormInputValue(formInputs.billing_city, paymentInfo.billing_city);

    prefillFormInputValue(formInputs.billing_state, paymentInfo.billing_state);

    prefillFormInputValue(formInputs.billing_province, paymentInfo.billing_state);

    prefillFormInputValue(formInputs.billing_zip, paymentInfo.billing_zip);
  };

  const orderGift = (hasSubmitted?: boolean) => {
    if (hasSubmitted) return;

    if (doesUserHaveEnoughCredit()) {
      orderGiftUsingCredit();
    } else {
      orderGiftUsingCard();
    }
  };

  const orderGiftUsingCredit = () => {
    validateBillingEmail();

    let errorString = "";

    if (formInputs.billing_email.value === "") {
      errorString = "Please fill out all fields!";
    }

    if (errorString.length > 0) {
      Toast.fail(errorString, 500);
      throw new Error(errorString);
    }

    setState({
      ...pageData,
      checkoutInProgress: true
    });

    const total_price_converted = pageData.checkoutData
      ? pageData.checkoutData.total_price_converted
      : pageData.gift.pricing.total_price_converted;
    
    const userId = props.match.params.receiverId === "myself" ? "guest" : props.match.params.receiverId;
    axios
      .post("/api/orderGift", {
        receiver_user_id: userId,
        gift_id: props.match.params.giftId,
        total_price_converted: total_price_converted,
        billing_email: formInputs.billing_email.value,
        special_message: includeSpecialMessage ? formInputs.special_message.value.trim() : undefined,
        sender_nickname: formInputs.sender_name.value,
        credit_amount_converted: pageData.checkoutData ? pageData.checkoutData.credit_amount_converted : undefined,
        credit_profile_id: pageData.checkoutData ? pageData.checkoutData.credit_profile_id : undefined
      })
      .then((response) => {
        ReactPixel.track("track", "PURCHASE", {
          currency: "USD",
          price: total_price_converted,
          transaction_id: response.data.order.order_data.order_id
        });

        // 2 = credit and card, 1 = just card, 0 = credit
        const paymentType = "/0";
        props.history.push(
          "/checkoutresult/" +
            props.match.params.receiverId +
            "/" +
            response.data.order.order_data.order_id +
            paymentType,
          {
            email: formInputs.billing_email.value,
            ...response.data
          }
        );
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          checkoutInProgress: false,
          error: error
        }));

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const orderGiftUsingCard = () => {
    validateCheckoutForm();

    let errorString = "";
    errorString = validateCard();

    if (
      formInputs.billing_name.value === "" ||
      formInputs.billing_address.value === "" ||
      formInputs.billing_city.value === "" ||
      formInputs.billing_country.value === "" || // State is required only if the billing country is United States
      (formInputs.billing_country.value === "US" && formInputs.billing_state.value === "") || // Province is required only if the billing country is not United States
      (formInputs.billing_country.value !== "US" && formInputs.billing_province.value === "") ||
      formInputs.billing_zip.value === "" ||
      formInputs.billing_email.value === ""
    ) {
      errorString = "Please fill out all fields!";
    } else if (formInputs.card.error !== "") {
      errorString = formInputs.card.error;
    }

    if (errorString.length > 0) {
      Toast.fail(errorString, 500);
      throw new Error(errorString);
    }

    setState({
      ...pageData,
      checkoutInProgress: true
    });

    let state;

    if (formInputs.billing_country.value === "US") {
      state = formInputs.billing_state.value;
    } else {
      state = formInputs.billing_province.value;
    }

    const creditAmountConverted = pageData.checkoutData ? pageData.checkoutData.credit_amount_converted : undefined;

    let receiverId = props.match.params.receiverId;

    if (props.match.params.receiverId === "myself") {
      receiverId = "guest"

      if (!props.match.params.hasSession) {
        receiverId = pageData.user.encryptedUserId;
      }
    }

    const payload: OrderGiftPayloadObject = {
      receiver_user_id: receiverId,
      gift_id: props.match.params.giftId,
      total_price_converted: pageData.checkoutData
        ? pageData.checkoutData.total_price_converted
        : pageData.gift.pricing.total_price_converted,
      account_number: formInputs.card.cardNumber,
      billing_name: formInputs.billing_name.value,
      billing_address: formInputs.billing_address.value,
      billing_city: formInputs.billing_city.value,
      billing_state: state,
      billing_zip: formInputs.billing_zip.value,
      billing_country: formInputs.billing_country.value,
      billing_email: formInputs.billing_email.value,
      special_message: includeSpecialMessage ? formInputs.special_message.value.trim() : undefined,
      sender_nickname: formInputs.sender_name.value,
      sender_firstname: pageData.user ? pageData.user.firstname : "",
      sender_lastname: pageData.user ? pageData.user.lastname : "",
      sender_country: pageData.user ? pageData.user.country : "",
      credit_amount_converted: pageData.checkoutData ? pageData.checkoutData.credit_amount_converted : undefined,
      credit_profile_id: pageData.checkoutData ? pageData.checkoutData.credit_profile_id : undefined
    };

    if (pageData.paymentInfo && pageData.paymentInfo.payment_profile_id) {
      payload.payment_profile_id = pageData.paymentInfo.payment_profile_id;
    } else {
      payload.exp_month = formInputs.exp_month.value;
      payload.exp_year = `20${formInputs.exp_year.value}`;
      payload.csv = formInputs.csv.value;
    }

    axios
      .post("/api/orderGift", payload)
      .then((response) => {
        ReactPixel.track("track", "PURCHASE", {
          currency: "USD",
          price: payload.total_price_converted,
          transaction_id: response.data.order.order_data.order_id
        });

        const hasSessionUrl = props.match.params.hasSession ? `/${props.match.params.hasSession}` : "";

        // 2 = credit and card, 1 = just card, 0 = credit
        const paymentType = creditAmountConverted ? "/2" : "/1";

        props.history.push(
          "/checkoutresult/" +
            props.match.params.receiverId +
            "/" +
            response.data.order.order_data.order_id +
            paymentType +
            hasSessionUrl,
          {
            email: formInputs.billing_email.value,
            ...response.data
          }
        );
      })
      .catch((error) => {
        setState((prev) => ({
          ...prev,
          checkoutInProgress: false,
          error: error
        }));

        if (error.response.status === 500) {
          props.history.push("/error");
        }
      });
  };

  const openChoosePaymentMethodDialog = (e) => {
    e.preventDefault();

    setState({
      ...pageData,
      openChoosePaymentMethodDialog: true
    });
  };

  const validateBillingEmail = (inputs = formInputs) => {
    const formInput = inputs["billing_email"];
    formInput.error = formInput.value === "" ? "Email is required" : "";
  };

  const validateCheckoutForm = (inputs = formInputs) => {
    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("billing_name" === inputName) {
        formInput.error = formInput.value === "" ? "Full Name is required" : "";
      } else if ("card" === inputName && !pageData.paymentInfo) {
        const validatedCardInput = formInputs.card.onValidate();
        formInputs.card.cardNumber = validatedCardInput.cardNumber;
        formInputs.card.validation = validatedCardInput.validation;
        formInputs.card.error = validatedCardInput.error;

        // TODO DO SOMETHING WITH EXPIRATION
      } else if ("exp_month" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration month is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter a 2 digit expiry month";
        } else {
          formInput.error = "";
        }
      } else if ("exp_year" === inputName) {
        if (formInput.value === "") {
          formInput.error = "Expiration year is required";
        } else if (formInput.value.length !== 2) {
          formInput.error = "Please enter the last 2 digits of expiry year!";
        } else {
          formInput.error = "";
        }
      } else if ("csv" === inputName) {
        validateCSV(formInput);
      } else if ("billing_address" === inputName) {
        formInput.error = formInput.value === "" ? "Billing Address is required" : "";
      } else if ("billing_country" === inputName) {
        formInput.error = formInput.value === "" ? "Country is required" : "";
      } else if ("billing_city" === inputName) {
        formInput.error = formInput.value === "" ? "City is required" : "";
      } else if ("billing_state" === inputName && formInputs["billing_country"].value === "US") {
        formInput.error = formInput.value === "" ? "State is required" : "";
      } else if ("billing_province" === inputName && formInputs["billing_country"].value !== "US") {
        formInput.error = formInput.value === "" ? "State/Province is required" : "";
      } else if ("billing_zip" === inputName) {
        formInput.error = formInput.value === "" ? "Zip Code is required" : "";
      } else if ("billing_email" === inputName) {
        formInput.error = formInput.value === "" ? "Email is required" : "";
      }
    }
  };

  const validateCSV = (csvInput) => {
    if (csvInput.value === "") {
      csvInput.error = "Card " + csvInput.label + " is required";
    } else if (csvInput.value.length !== csvInput.maxLength) {
      csvInput.error = csvInput.maxLength + " digit " + csvInput.label + " required";
    } else {
      csvInput.error = "";
    }
  };

  const onBindCardValidate = (onValidateHook) => {
    formInputs.card.onValidate = onValidateHook;
  };

  const onCardInputDataUpdated = (cardNumber, validation, error) => {
    formInputs.card.cardNumber = cardNumber;
    formInputs.card.validation = validation;
    formInputs.card.error = error;

    let csvLabel;
    let csvMaxLength;
    const csvInput = formInputs.csv;
    const card = validation && validation.card ? validation.card : undefined;

    if (card) {
      csvLabel = card.code.name;
      csvMaxLength = card.code.size;
    } else {
      csvLabel = CARD_CVV_LABEL;
      csvMaxLength = CARD_CVV_SIZE;
    }

    if (csvLabel !== csvInput.label || csvMaxLength !== csvInput.maxLength) {
      csvInput.label = csvLabel;
      csvInput.maxLength = csvMaxLength;

      if (csvInput.value !== "" || csvInput.error !== "") {
        validateCSV(csvInput);
      }

      setFormInputs({
        ...formInputs
      });
    }
  };

  const navigateToCheckoutResult = () => {
    setIsToSubmit(true);

    if (!pageData.checkoutInProgress) {
      try {
        orderGift(hasSubmitted);
        if (!hasSubmitted) {
          setHasSubmitted(true);
        }
      } catch (e) {
        setIsToSubmit(false);

        setState((prev) => ({
          ...prev,
          checkoutInProgress: false,
          error: new Error("Error on checkout navigation")
        }));
      }
    }
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateCheckoutForm
  );

  const onPaymentMethodSelected = (paymentMethod) => {
    setState({
      ...pageData,
      paymentInfo: { ...paymentMethod },
      openChoosePaymentMethodDialog: false
    });

    populateBillingInfo(paymentMethod);
    setFormInputs({
      ...formInputs
    });
  };

  const populateCityAndState = (city) => {
    formInputs.billing_city.value = city;

    const selectedAddress = rawAddresses.find((address) => address.city.toLowerCase() === city.toLowerCase());
    if (selectedAddress) {
      formInputs.billing_state.value = selectedAddress.state;
    }

    setFormInputs({
      ...formInputs
    });
  };

  useEffect(() => {
    if (!props.match.params.hasSession) {
      fetchUser();
    }

    setTimeout(() => {
      setIsLongLoading(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (pageData.user) {
      if (!props.location.state) {
        fetchGift();
      } else {
        if (!pageData.checkoutData) {
          getCheckoutData();
        }
      }
    }
  }, [pageData.user]);

  useEffect(() => {
    if (pageData.user && pageData.gift) {
      getCheckoutData();
    }
  }, [pageData.gift]);

  useEffect(() => {
    if (pageData.user && !pageData.receiverUser) {
      fetchReceiverUser();
    }
  }, [pageData.checkoutData]);

  useEffect(() => {
    if (pageData.user && pageData.receiverUser && !isVysionPaymentEventTriggered) {
      vysionPaymentEvent({
        eventName: EVENT_PAYMENT_ATTEMPT,
        giftId: props.match.params.giftId,
        senderId: pageData.user.user_id,
        receiverId: pageData.receiverUser.user_id
      }).then(() => {
        setIsVysionPaymentEventTriggered(true);
      });
    }
  }, [props.match.params.giftId, pageData.user, pageData.receiverUser]);

  useEffect(() => {
    if (formInputs.billing_country.value === "US" && formInputs.billing_zip.value.length >= 5) {
      getAddressByZipCode({
        zipcode: formInputs.billing_zip.value,
        country: formInputs.billing_country.value
      }).then((addresses) => {
        if (addresses) {
          const formattedAddresses = addresses.map((address) => {
            const city = address.city.toLowerCase();
            const capitalizedCity = city.replace(/\b[a-z]/g, (match) => match.toUpperCase());
            return {
              label: capitalizedCity,
              value: capitalizedCity
            };
          });
          setAddresses(formattedAddresses);
          setRawAddresses(addresses);
        } else {
          setAddresses([]);
          setRawAddresses([]);
        }
      });
    }
  }, [formInputs.billing_zip.value, formInputs.billing_country.value]);

  if (
    !props.match.params.hasSession &&
    (pageData.receiverUser === undefined || pageData.gift === undefined || pageData.checkoutData === undefined)
  ) {
    return (
      <Fragment>
        <Box className={styles.root}>
          <Box height={150} display="flex" marginX="auto" flexDirection="column">
            <CircularProgress
              style={{
                marginTop: "50px",
                alignSelf: "center",
                marginBottom: "25px"
              }}
            />
            {isLongLoading && (
              <Typography
                style={{
                  textAlign: "center",
                  color: "#aaaaaa"
                }}
              >
                Please wait, almost done!
              </Typography>
            )}
          </Box>
        </Box>
      </Fragment>
    );
  } else {
    const itemCost =
      CURRENCY +
      roundToDecimalPlaces(
        pageData.checkoutData ? pageData.checkoutData.sale_price_dollars : pageData.gift.pricing.sale_price_dollars,
        2
      );
    const totalPriceConverted =
      CURRENCY +
      roundToDecimalPlaces(
        pageData.checkoutData
          ? pageData.checkoutData.total_price_converted
          : pageData.gift.pricing.total_price_converted,
        2
      );

    const paymentInfo = () => {
      if (!pageData.paymentInfo) {
        return (
          <Box className={styles.paymentInfoContainer}>
            <SectionTitle>Payment Info</SectionTitle>
            <img
              className={styles.supportedCardsImg}
              style={{ marginTop: "8px" }}
              src={SupportedCards}
              alt="supported cards"
            />
            <Caption style={{ marginTop: "16px" }}>
              Your details are private and confidential. Purchases show as ‘RealGifts’
            </Caption>
            <PaymentInformationForm
              billing_name={formInputs.billing_name ? formInputs.billing_name : ""}
              exp_month={formInputs.exp_month ? formInputs.exp_month : ""}
              exp_year={formInputs.exp_year ? formInputs.exp_year : ""}
              csv={formInputs.csv ? formInputs.csv : ""}
              onBindCardValidate={onBindCardValidate}
              onCardInputDataUpdated={onCardInputDataUpdated}
              handleInputChange={handleInputChange}
              handleOnBlur={handleOnBlur}
              handleOnFocus={handleOnFocus}
              style={{ marginTop: "16px" }}
            />
          </Box>
        );
      } else {
        return (
          <div>
            <Box className={styles.paymentInfoContainer}>
              <SectionTitle>Payment Info</SectionTitle>
              <img
                className={styles.supportedCardsImg}
                style={{ marginTop: "8px" }}
                src={SupportedCards}
                alt="supported cards"
              />
              <Caption style={{ marginTop: "16px" }}>
                Your details are private and confidential. Purchases show as ‘RealGifts’
              </Caption>
            </Box>
            <SavedCheckoutPaymentInfo
              openChoosePaymentMethodDialog={openChoosePaymentMethodDialog}
              paymentInfo={pageData.paymentInfo}
              disabled={isToSubmit}
            />
          </div>
        );
      }
    };

    const billingInfo = () => {
      return (
        <Box className={styles.billingInfoContainer}>
          <SectionTitle>Billing info</SectionTitle>
          <BillingInformationForm
            billing_address={formInputs.billing_address ? formInputs.billing_address : ""}
            billing_zip={formInputs.billing_zip ? formInputs.billing_zip : ""}
            billing_city={formInputs.billing_city ? formInputs.billing_city : ""}
            billing_state={formInputs.billing_state ? formInputs.billing_state : ""}
            billing_province={formInputs.billing_province ? formInputs.billing_province : ""}
            billing_country={formInputs.billing_country ? formInputs.billing_country : ""}
            billing_email={formInputs.billing_email ? formInputs.billing_email : ""}
            handleInputChange={handleInputChange}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            disabled={isToSubmit}
            addresses={addresses}
            populateCityAndState={populateCityAndState}
          />
        </Box>
      );
    };

    const billingInfoEmail = () => {
      return (
        <Box className={styles.billingInfoContainer}>
          <SectionTitle>Billing info</SectionTitle>
          <BillingInformationEmailForm
            billing_email={formInputs.billing_email ? formInputs.billing_email : ""}
            handleInputChange={handleInputChange}
            handleOnBlur={handleOnBlur}
            handleOnFocus={handleOnFocus}
            disabled={isToSubmit}
          />
        </Box>
      );
    };

    return (
      <Fragment>
        {props.match.params.hasSession && (
          <Box
            className={styles.headerGuestCheckoutContainer}
            style={{
              backgroundColor: "#35EEDF"
            }}
          >
            <Typography
              style={{
                padding: "20px",
                color: "#333333",
                fontSize: "16px",
                textAlign: "center"
              }}
            >
              <i>
                You are checking out as a <b>Guest</b>.
              </i>{" "}
              <Link
                href="#"
                underline="always"
                onClick={(e) => {
                  e.preventDefault();
                  setPromptLogin(true);
                  setIsSignUp(true);
                }}
              >
                Sign up
              </Link>
              . Have an account?{" "}
              <Link
                href="#"
                underline="always"
                onClick={(e) => {
                  e.preventDefault();
                  setPromptLogin(true);
                  setIsSignUp(false);
                }}
              >
                Log in
              </Link>{" "}
              here
            </Typography>
            <LoginDialog
              returnTo={window.location.pathname.replace("/guest", "")}
              open={promptLogin}
              forceRegistration={isSignUp}
              onClose={() => {
                setPromptLogin(false);
              }}
            />
          </Box>
        )}
        <Box className={styles.root}>
          <Card className={styles.leftCard}>
            <CardContent className={styles.cardContent}>
              <CheckoutSpecialMessage
                receiverNickname={
                  pageData.receiverUser
                    ? pageData.receiverUser.nickname
                    : pageData.guestCheckoutReceiverNickname || "Myself"
                }
                sender_name={formInputs.sender_name ? formInputs.sender_name : ""}
                special_message={formInputs.special_message ? formInputs.special_message : ""}
                isDesktop={isDesktop}
                gift={pageData.gift}
                itemCost={itemCost}
                handleInputChange={handleInputChange}
                handleOnBlur={handleOnBlur}
                handleOnFocus={handleOnFocus}
                disabled={isToSubmit}
                includeSpecialMessage={includeSpecialMessage}
                handleIncludeSpecialMessageChange={(event) => {
                  setIncludeSpecialMessage(event.target.checked);
                }}
              />

              {doesUserHaveEnoughCredit() ? null : paymentInfo()}
              {doesUserHaveEnoughCredit() ? billingInfoEmail() : billingInfo()}

              {isDesktop && (
                <Fragment>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{
                      marginTop: 25,
                      backgroundColor: "rgba(0,0,0,0.1)",
                      padding: "14px 16px"
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }}>Order total:</Typography>
                    <Typography style={{ fontWeight: "bold" }}>{totalPriceConverted}</Typography>
                  </Box>
                  <PrimaryButton
                    className={styles.orderButton}
                    handleClick={() => navigateToCheckoutResult()}
                    disabled={isToSubmit}
                  >
                    <CircularProgress
                      className={styles.orderCircularProgress}
                      style={{ display: isToSubmit ? "inline-block" : "none" }}
                    />
                    <img src={ImageLock} alt="secure" style={{ color: "#EED635", marginRight: "5px" }} />
                    Place Order
                  </PrimaryButton>
                </Fragment>
              )}
            </CardContent>
          </Card>
          {!isDesktop && (
            <Card
              style={{
                marginTop: "19px",
                borderRadius: 8,
                boxShadow: "inset 0 -1px 0 0 #E3E3E3, 0 1px 7px 0 rgba(0,0,0,0.15)"
              }}
            >
              <CardContent>
                <GiftSummary
                  price={
                    pageData.checkoutData
                      ? pageData.checkoutData.sale_price_dollars
                      : pageData.gift.pricing.sale_price_dollars
                  }
                  checkoutData={pageData.checkoutData}
                  gift={pageData.gift}
                  receiverNickname={
                    pageData.receiverUser
                      ? pageData.receiverUser.nickname
                      : pageData.guestCheckoutReceiverNickname || "Myself"
                  }
                  style={{ marginTop: "19px" }}
                />
                <PrimaryButton
                  className={styles.orderButton}
                  handleClick={() => navigateToCheckoutResult()}
                  disabled={isToSubmit}
                >
                  <CircularProgress
                    className={styles.orderCircularProgress}
                    style={{ display: isToSubmit ? "inline-block" : "none" }}
                  />
                  <img src={ImageLock} alt="secure" style={{ color: "#EED635", marginRight: "5px" }} />
                  Place Order
                </PrimaryButton>
              </CardContent>
            </Card>
          )}
          {isDesktop && (
            <Card className={styles.rightCard}>
              <CardContent className={styles.cardContent}>
                <CheckoutGiftDetail
                  imageUrl={pageData.gift.image_url}
                  giftName={pageData.gift.name.replace("&apos;", "'")}
                  receiverNickname={
                    pageData.receiverUser
                      ? pageData.receiverUser.nickname
                      : pageData.guestCheckoutReceiverNickname || "Myself"
                  }
                  itemCost={itemCost}
                />
                <Divider orientation={"horizontal"} variant={"fullWidth"} />
                <GiftSummary
                  price={
                    pageData.checkoutData
                      ? pageData.checkoutData.sale_price_dollars
                      : pageData.gift.pricing.sale_price_dollars
                  }
                  checkoutData={pageData.checkoutData}
                  gift={pageData.gift}
                  receiverNickname={
                    pageData.receiverUser
                      ? pageData.receiverUser.nickname
                      : pageData.guestCheckoutReceiverNickname || "Myself"
                  }
                  style={{ marginTop: "19px" }}
                />
              </CardContent>
            </Card>
          )}

          <PaymentMethodDialog
            open={pageData.openChoosePaymentMethodDialog}
            onClose={() =>
              setState({
                ...pageData,
                openChoosePaymentMethodDialog: false
              })
            }
            paymentInfos={pageData.paymentInfos}
            paymentInfo={pageData.paymentInfo}
            billingName={formInputs.billing_name.value}
            onPaymentMethodSelected={onPaymentMethodSelected}
          />

          <ChangeBillingInfoDialog
            open={pageData.openChooseBillingAddressDialog}
            onClose={() =>
              setState({
                ...pageData,
                openChooseBillingAddressDialog: false
              })
            }
            paymentInfos={pageData.paymentInfos}
            email={pageData.user ? pageData.user.email : ""}
          />
        </Box>
        <AllRightReservedComponent />
      </Fragment>
    );
  }
}
