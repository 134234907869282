import React from "react";
import { Box, Typography } from "@material-ui/core";
import GifterSpecialNote from "./GifterSpecialNote";
import ReceivedGiftImage from "./ReceivedGiftImage";
import RedeemGiftDialogButtons from "./RedeemGiftDialogButtons";
import { RedeemGiftNameStyle } from "./RedeemGiftFlowStyles";
import { makeStyles } from "@material-ui/core/styles";
import AppTable, { createRow } from "../common/table/AppTable";

const GiftRedeemedDialogStyle = makeStyles(() => ({
  tableCell: {
    borderBottom: "none",
    padding: "8px 0px"
  },
  deliverToFirstCell: {
    borderBottom: "none",
    padding: "8px 0px 2px 0px"
  },
  deliverToFollowUpCell: {
    borderBottom: "none",
    padding: "2px 0px"
  }
}));

export default function GiftRedeemedDialogContent(props: any) {
  const giftRedeemedDialogStyle = GiftRedeemedDialogStyle();
  const redeemGiftNameStyle = RedeemGiftNameStyle();

  const createGiftOptionRows = () => {
    return props.giftOptions.map((item) => {
      return createRow(`${item.label}:`, item.value);
    });
  };

  const rows = [
    createRow("Order #:", props.order.order_id),
    // createRow("Season:", "Credit Card"),  // TODO I don't think we support seasonal data yet.
    ...createGiftOptionRows(),
    createRow(
      "Deliver to:",
      props.claimAddress.delivery_first_name + " " + props.claimAddress.delivery_last_name,
      giftRedeemedDialogStyle.deliverToFirstCell
    ),
    createRow("", props.claimAddress.delivery_address, giftRedeemedDialogStyle.deliverToFollowUpCell),
    createRow(
      "",
      props.claimAddress.delivery_city + ", " + props.claimAddress.delivery_state,
      giftRedeemedDialogStyle.deliverToFollowUpCell
    ),
    createRow(
      "",
      props.claimAddress.delivery_zip + " " + props.claimAddress.delivery_country,
      giftRedeemedDialogStyle.deliverToFollowUpCell
    ),
    createRow("", props.claimAddress.delivery_phone, giftRedeemedDialogStyle.deliverToFollowUpCell)
  ];

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" style={{ paddingBottom: "16px" }}>
      <ReceivedGiftImage
        alt="Gift Image"
        src={props.order.gift.image_url}
        style={{ alignSelf: "center", marginTop: "16px" }}
      />

      <Typography variant="h6" className={redeemGiftNameStyle.textStyle} style={{ marginTop: "16px" }}>
        {props.order.gift.name}
      </Typography>

      <AppTable rows={rows} classes={giftRedeemedDialogStyle} valueAlign="left" style={{ marginTop: "16px" }} />

      <GifterSpecialNote
        specialNote={props.order.message}
        gifterName={props.buyerNickname}
        style={{ marginTop: "16px" }}
      />

      <RedeemGiftDialogButtons
        positiveButtonText="Ok, go to wishlist"
        onPositiveButtonClick={props.onOpenWishlistClicked}
      />
    </Box>
  );
}
