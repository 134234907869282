import React, { useMemo } from "react";
import clsx from "clsx";
import { Box, makeStyles, Theme, BoxProps, Grid, Typography, useMediaQuery } from "@material-ui/core";
import TermsAndConditionsV2 from "../../termsAndConditions/TermsAndConditionsV2";
import SocialMediaLinks from "../socialMediaLinks/SocialMediaLinks";
import GridWrapper from "../../homeV4/common/GridWrapper";
import { COLORS, textColor } from "../../../style";
import useNav from "../../../hooks/useNav";
import { useApp } from "../../../context/app-context";
import { STOREHOME_PATH, HOW_IT_WORKS_PATH, CONTACT_US_PATH, HOME_PATH } from "../../../constants/paths";
const useStyles = ({ encryptedUserId }) => {
  return makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      backgroundSize: "cover",
      position: "relative",
      backgroundColor: "#02060B",
      paddingTop: 56,
      [theme.breakpoints.down("xs")]: {
        paddingTop: 45
      }
    },
    content: {
      display: "flex",
      height: "100%"
    },
    footerContainer: {
      background: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 35,
      paddingBottom: 25,
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: "-68px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "unset"
      },
      [theme.breakpoints.down("xs")]: {
        display: "block",
        paddingLeft: 28,
        paddingRight: 28,
        marginBottom: "unset"
      }
    },
    footerMenu: {
      listStyle: "none",
      margin: 0,
      padding: 0
    },
    footerMenuList: {
      marginTop: 8,
      marginBottom: 8
    },
    footerMenuLink: {
      fontWeight: 400,
      color: COLORS.WHITE,
      cursor: "pointer",
      "&:hover": {
        color: COLORS.PINK
      }
    },
    footerMenuLinkDownload: {
      fontWeight: 400,
      color: COLORS.WHITE,
      cursor: "pointer",
      "&:hover": {
        color: COLORS.PINK
      },
      marginLeft: 8,
      marginRight: 8
    },
    socialLinks: {
      [theme.breakpoints.down("xs")]: {
        marginTop: 10,
        marginBottom: 15
      }
    },
    footerWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      marginBottom: 40,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "space-between"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "space-between"
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        marginBottom: 0
      },
      "&:last-child": {
        paddingBottom: 20,
        marginBottom: 0,
        [theme.breakpoints.down("xs")]: {
          paddingBottom: 80
        }
      }
    },
    footerMenuContainer: {
      width: 468,
      display: "flex",
      justifyContent: encryptedUserId ? "flex-end" : "space-between",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        marginTop: 40
      }
    },
    footerMenuWrapper: {
      marginLeft: encryptedUserId ? 100 : "unset",
      [theme.breakpoints.down("xs")]: {
        flex: "50%",
        marginBottom: 20,
        marginLeft: "unset"
      }
    },
    footerLogo: {
      width: 94,
      [theme.breakpoints.down("xs")]: {
        margin: "auto"
      }
    }
  }));
};
interface AppFooterVariant1Props extends BoxProps {
  linkFontSize?: string;
  className?: string;
}

const AppFooterVariant1 = ({ className, linkFontSize, ...rest }: AppFooterVariant1Props): ReturnType<React.FC> => {
  const { goTo } = useNav();
  const { setPromptLogin, encryptedUserId } = useApp();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const classes = useStyles({
    encryptedUserId
  })();

  const notLoggedIn = [
    {
      title: "Account",
      items: [
        {
          label: "Login",
          action: () => setPromptLogin({ promptLogin: true, returnTo: undefined, registration: false })
        },
        {
          label: "Register",
          action: () =>
            setPromptLogin({
              promptLogin: true,
              returnTo: undefined,
              registration: true,
              forceRegistration: true
            })
        }
      ]
    }
  ];

  const footerMenu = useMemo(
    () => [
      {
        title: "Sitemap",
        items: [
          {
            label: "Shop",
            action: () => goTo(STOREHOME_PATH)
          },
          {
            label: "How To",
            action: () => goTo(HOW_IT_WORKS_PATH)
          },
          {
            label: "Contact Us",
            action: () => goTo(CONTACT_US_PATH)
          }
        ]
      },
      {
        title: "Company",
        items: [
          {
            label: "Privacy Policy",
            action: () => window.open("https://www.getrealgifts.com/home?page=privacy")
          },
          {
            label: "Terms",
            action: () => window.open("https://www.getrealgifts.com/home?page=terms")
          }
        ]
      },
      ...(!encryptedUserId ? notLoggedIn : [])
    ],
    [encryptedUserId]
  );

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <GridWrapper className={classes.content}>
        <Grid container spacing={4} style={{ height: "100%", marginBottom: isDesktop ? "0" : "unset" }}>
          <Grid item lg={12} className={classes.footerWrapper}>
            <img
              className={classes.footerLogo}
              src={"/assets/header_logo_v4.svg"}
              alt="GiftMe Logo"
              onClick={() => {
                goTo(HOME_PATH);
              }}
            />

            <Box className={classes.footerMenuContainer}>
              {footerMenu.map((menu, idx) => {
                return (
                  <Box key={idx} className={classes.footerMenuWrapper}>
                    <Typography variant="h6" style={{ fontWeight: 700, marginBottom: 20, color: textColor.WHITE }}>
                      {menu.title}
                    </Typography>
                    <ul className={classes.footerMenu}>
                      {menu.items.map((item, idx) => {
                        return (
                          <li key={idx} className={classes.footerMenuList}>
                            <Typography
                              variant="subtitle2"
                              className={classes.footerMenuLink}
                              onClick={() => item.action()}
                            >
                              {item.label}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                );
              })}
              {encryptedUserId ? (
                <Box key={3} className={classes.footerMenuWrapper}>
                  <Typography variant="h6" style={{ fontWeight: 700, marginBottom: 20, color: textColor.WHITE }}>
                    App
                  </Typography>
                  <ul className={classes.footerMenu}>
                    <a href="/me.gift.app-v1.0.1.apk" style={{ textDecoration: "none" }}>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <img src="/android.png" alt="Android" style={{ width: 30, height: 30 }} />
                        <Typography variant="subtitle2" className={classes.footerMenuLinkDownload}>
                          Android
                        </Typography>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                        </svg>
                      </Box>
                    </a>
                  </ul>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item lg={12} className={classes.footerWrapper} style={{ alignItems: isDesktop ? "center" : "unset" }}>
            <TermsAndConditionsV2
              textAlign="left"
              justifyContent="flex-start"
              alignItems="baseline"
              style={{
                display: "flex",
                alignItems: "baseline",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "26px",
                color: COLORS.WHITE,
                flexDirection: isDesktop ? "row" : "column"
              }}
              linkFontSize={linkFontSize}
            />
            <SocialMediaLinks className={classes.socialLinks} />
          </Grid>
        </Grid>
      </GridWrapper>
    </Box>
  );
};

export default AppFooterVariant1;
