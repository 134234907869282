import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        backgroundColor: "rgba(255, 255, 255, 0.3)"
      }
    }
  }
})(TextField);

interface TextInputProps {
  defaultText?: string;
  marginLeft?: string;
  marginTop?: string;
  marginRight?: string;
  alignSelf?: string;
  width?: string;
  showErrorIcon?: boolean;
  showSuccessIcon?: boolean;
  maxLength?: string | number;
  handleOnBlur?: (e: any) => void; // not in use , but will add as prop in order not to break the existing
  handleOnFocus?: (e: any) => void; // not in use , but will add prop in order not to break the existing
}

type TextInputComponentProps = TextFieldProps & TextInputProps;

const TextInput = ({
  marginTop,
  marginLeft,
  marginRight,
  alignSelf,
  width,
  showSuccessIcon,
  showErrorIcon,
  variant = "outlined",
  defaultText,
  error,
  maxLength,
  ...rest
}: TextInputComponentProps) => {
  console.log("marginTop", marginTop);
  return (
    <CustomTextField
      {...rest}
      defaultValue={defaultText}
      variant={variant}
      error={error}
      style={{
        marginTop,
        marginLeft,
        marginRight,
        alignSelf,
        width,
        ...rest.style
      }}
      inputProps={{ ...rest.inputProps, maxLength }}
    />
  );
};

export default TextInput;
