import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Link, Theme, Typography, useMediaQuery, Grid } from "@material-ui/core/";
import PreferToChat from "./PreferToChat";
import { makeStyles } from "@material-ui/core/styles";
import { backgroundTheme, textColor } from "../../style";
import Form, { FormInput } from "../input/Form";
import ContactUsForm from "./ContactUsForm";
import Toast from "light-toast";
import AppLayout from "../common/appLayout/AppLayout";
import theme from "../../theme/theme-devins";
import Section from "../common/appLayout/Section";
import { FOOTER_VARIANT2 } from "../common/appFooter/FooterVariants";

const useStyles = makeStyles(() => ({
  title: {
    padding: "40px 16px 30px 0px",
    marginRight: "10px",
    color: textColor.GRAY
  },
  text: {
    fontSize: "12px",
    paddingBottom: "20px"
  },
  email: {
    fontWeight: 600,
    textDecoration: "underline",
    color: textColor.NORMAL,
    paddingBottom: "20px"
  },
  image: {
    width: "100%",
    padding: "40px 0"
  },
  input: {
    fontSize: 16,
    paddingBottom: "20px"
  },
  button: {
    width: "32%"
  }
}));

const initialFormInputs = {
  name: FormInput(),
  email: FormInput(),
  subject: FormInput(),
  description: FormInput()
};

export default function ContactUs() {
  const classes = useStyles();

  const [isFetching, setIsFetching] = useState(false);

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const contactUs = () => {
    setIsFetching(true);

    // @ts-ignore
    embedded_svc.bootstrapEmbeddedService();

    const intervalID = setInterval(function () {
      if (document.getElementsByClassName("dockableContainer").length > 0) {
        const dockableContainer = document.querySelectorAll(".dockableContainer");
        // @ts-ignore
        for (const el of dockableContainer) {
          el.getElementsByClassName("sidebarHeader")[0].children[0].style.display = "none";
        }
        clearInterval(intervalID);
        setIsFetching(false);
      }
    }, 3000);
  };

  const validateForm = (inputs = formInputs) => {
    let hasError;
    for (const inputName in inputs) {
      const formInput = inputs[inputName];

      if ("name" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("email" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("subject" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      } else if ("description" === inputName) {
        formInput.error = formInput.value === "" ? "This is required" : "";
      }

      hasError = hasError ? hasError : formInput.error !== "";
    }

    return !hasError;
  };

  const { formInputs, setFormInputs, handleInputChange, handleOnFocus, handleOnBlur } = Form(
    initialFormInputs,
    validateForm
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setFormInputs({ ...formInputs });

      return;
    }

    // @ts-ignore
    document.getElementById("subject").value = "Gift.Me " + document.getElementById("subject").value;
    // @ts-ignore
    document.getElementById("formContactUs").submit();

    Toast.info("Successfully submitted your inquiry...", 1000);
  };

  useEffect(() => {
    const metaType = document.createElement("meta");
    metaType.httpEquiv = "Content-type";
    metaType.content = "text/html; charset=UTF-8";
    metaType.setAttribute("data-react-helmet", "true");
    document.head.appendChild(metaType);

    const metaViewport = document.createElement("meta");
    metaViewport.name = "viewport";
    metaViewport.content = "width=device-width, initial-scale=1, minimum-scale=1";
    metaViewport.setAttribute("data-react-helmet", "true");
    document.head.appendChild(metaViewport);

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://service.force.com/embeddedservice/5.0/esw.min.js";
    script.addEventListener("load", () => {
      const initESW = function (gslbBaseURL) {
        console.log("gslbBaseURL ", gslbBaseURL);
        // @ts-ignore
        embedded_svc.settings.displayHelpButton = false; //Or false
        // @ts-ignore
        embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'
        //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
        //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
        //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)

        const url = window.location.hostname;
        const domain = url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];

        // @ts-ignore
        embedded_svc.settings.storageDomain = domain; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

        // Settings for Chat
        // @ts-ignore
        embedded_svc.settings.directToButtonRouting = function (prechatFormData) {
          // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
          // Returns a valid button ID.
        };

        //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
        //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
        //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
        // @ts-ignore
        embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        // @ts-ignore
        embedded_svc.settings.entryFeature = "LiveAgent";
        // @ts-ignore
        embedded_svc.init(
          "https://reflexmedia.my.salesforce.com",
          "https://reflexmedia.secure.force.com/liveAgentSetupFlow",
          gslbBaseURL,
          "00D4x000006sRrL",
          "Gift_Me",
          {
            baseLiveAgentContentURL: "https://c.la3-c1-ia4.salesforceliveagent.com/content",
            deploymentId: "5724x000000p48l",
            buttonId: "5734x000000p4Vk",
            baseLiveAgentURL: "https://d.la3-c1-ia4.salesforceliveagent.com/chat",
            eswLiveAgentDevName: "Reflex_General_Queue_2",
            isOfflineSupportEnabled: true
          }
        );
      };

      // @ts-ignore
      if (!window.embedded_svc) {
        const s = document.createElement("script");
        s.setAttribute("src", "https://reflexmedia.my.salesforce.com/embeddedservice/5.0/esw.min.js");
        s.onload = function () {
          initESW(null);
        };

        document.body.appendChild(s);
      } else {
        initESW("https://service.force.com");
      }
    });

    document.body.appendChild(script);
  }, []);

  return (
    <AppLayout
      theme={theme}
      headerVariant="header1"
      backgroundColor={backgroundTheme.grayFill}
      footerVariant={FOOTER_VARIANT2}
    >
      <Section backgroundImage="url(/assets/secondary_bg.svg)">
        <Box
          style={{
            background: "url(/assets/secondary_bg.svg) center top / 100% no-repeat"
          }}
        >
          {isFetching ? (
            <Box
              style={{
                height: "150px",
                justifyContent: "center",
                position: "fixed",
                left: "49%",
                top: "50%",
                zIndex: 9999
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          <Grid container spacing={1}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Box
                style={
                  isDesktop
                    ? {
                        margin: "40px 90px 0 0"
                      }
                    : {
                        margin: "25px 15px 0 15px"
                      }
                }
              >
                <div>
                  <Typography variant="h4" className={classes.title}>
                    Contact Us
                  </Typography>
                  <Typography variant="body2" className={classes.text}>
                    Questions on how to send or receive the perfect gifts? We’re here to help!
                    <br />
                    You can also email us directly at:
                  </Typography>
                  <Typography variant="body1">
                    <a href="mailto:hello@gift.me" className={classes.email}>
                      hello@gift.me
                    </a>
                  </Typography>
                  {isDesktop && <img src="/assets/contact.png" alt="contact-us" className={classes.image} />}
                </div>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box
                style={
                  isDesktop
                    ? {
                        margin: "40px 15px 0 0"
                      }
                    : {
                        margin: "25px 15px 0 15px"
                      }
                }
              >
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={
                    isDesktop
                      ? {
                          paddingTop: "40px"
                        }
                      : {
                          paddingTop: "0px"
                        }
                  }
                >
                  If you're looking for an immediate answer, we've covered the most common questions we get in our
                  &nbsp;
                  <Link href={"/faq"} target={"_blank"} rel="noopener" underline="none">
                    FAQs
                  </Link>
                  .
                </Typography>
                <ContactUsForm
                  name={formInputs.name}
                  email={formInputs.email}
                  subject={formInputs.subject}
                  description={formInputs.description}
                  handleInputChange={handleInputChange}
                  handleOnFocus={handleOnFocus}
                  handleOnBlur={handleOnBlur}
                  handleOnSubmit={handleOnSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Section>
      <PreferToChat handleClick={contactUs} />
    </AppLayout>
  );
}
